import { PostProps } from "@/types"

const post13: PostProps = {
  key: 13,
  x: '40%',
  y: '88%',
  location: 'Crete',
  title: 'Last city trip and a goodbye',
  content: [
    {
      type: 'text',
      value: [
        `We planned to spend the last day exploring the town. We got off the free bus at the Agios Minas church and I have to say, it was one of the most interesting churches I have seen. (I mean, look at the pictures).`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post13/1.jpg',
          description: ['stained glass window and its reflection'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post13/2.jpg',
          description: ['beautiful chandelier and decorated interior'],
          color: 'blue',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Afterwards, we sat on a bench and marked some seemingly interesting places in the town. We went to see some statues in a park and we discovered a free museum by accident. There was an entrance into one of the city walls and inside, there was a tunnel-like long museum, with a lot of texts and pictures of Nikos Kazantzákis, an incredibly smart writer and translator, who amongst other things wrote Zorba the Greek (the movie contains the famous ‘greek dance’). I really love finding museums and galleries by accident, and this one was especially interesting since it was inside a city wall tunnel.`,
        `Later in the afternoon, we returned to the church and went to a cafe at the square behind the church. We removed our sandals and made ourselves comfortable. We needed a lot of strength because we pledged to walk to the lighthouse, which is a big walk.`,
        `First, we thought we had to pay to get to the promenade which leads to the lighthouse, because we got swallowed by a museum. But don’t let this fool you, you can walk around the museum to just take a walk. Still, I have a feeling a lot of people paid just to pass by the museum to get to the other side.`,
        `I chose to climb the stairs to the top of the wall which protects the city from sea waves. I walked on top and my mom was nervous. But I assured her it was safe, the wall was about 1.5 metres wide, and although it was about 3 metres tall, there was little risk of me falling. The wall apparently had beautiful street art on it, again with much Greek and Minoan symbolism. My mom kept gasping and taking pictures, and I was up on that wall getting more and more curious about what she might be seeing there. Eventually, she joined me and we held hands to make it feel more safe. She even managed to climb around a gate covering the whole top of the wall, which suddenly surprised us.`,
        `The lighthouse was not too interesting, it was pretty industrial, not at all historical as the one in Rethymno. But the journey there was more interesting. On the way back, I went onto the road instead of the wall, to see the paintings on it.`,
        `By the time we got home, we were pretty much dead. We still managed to go for a swim and even brought goggles!`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post13/3.jpg',
          description: ['walking on top of the big wall'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post13/4.jpg',
          description: ['Phaistos Disc', '- art on the wall'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `The next morning, we also still managed to swim in the morning. In the afternoon, we sat together in a cafe at the airport. We reminisced about this wonderful vacation. After saying goodbye, my mom flew back home and I walked to a nearby cafe. It was close to a loud road and there was loud music playing inside, but it had wifi and plugs, so I could work on some things. I had a lot of travel ahead.`,
        `In the evening, I made it to the ferry. This one was a lot less comfortable for sleep than the one on the way here. There was some football match until late at night and a lot of loud men were gathered around a TV and commented on it (loudly so). I arrived in Athens too early, but was lucky enough there was a bakery opened at the port. This one also had everything I needed and more. My next step was to go to Thessaloniki and meet-up with Clara from the first hostel! The train tracks were in reconstruction, so the train took five hours instead of three and that cut off some time I had with Clara.`,
        `Even for a short time, it was wonderful meeting her. We caught up on a lot of updates and enjoyed dinner together. We talked and enjoyed until I had to move to the bus station to continue my ridiculously long journey to Istanbul.`
      ]
    }
  ],
  signature: '28/09/2023 - 30/09/2023'
}

export default post13