import { PostProps } from "@/types"

const post21: PostProps = {
  key: 21,
  x: '90%',
  y: '72%',
  location: 'Taşucu',
  title: 'The drama with the missed ferry',
  content: [
    {
      type: 'text',
      value: [
        `I walked towards the gate where a man in uniform checked the tickets. I showed him my ticket. 'Go check in. Go check in,' he started repeating and I assumed he would let me in the port to check in. However, he kept blocking the entrance. 'Okay, where do I check in?' I asked.`,
        `'In the office.'`,
        `'Where is the office?' I looked around the port, trying to see behind his shoulders if I could see one at the port.`,
        `'In town. Check-in in town!'`,
        `It felt like a punch in my belly. He pulled out his phone and showed me Google maps, which basically outlined the same route I just struggled through but in the opposite direction. I told him there is no way I can make it there and back in time. He did not hesitate and put me in a taxi with two men who appeared to have the same problem. There was a big construction site at the port that we had to go around, so maybe that is why the office was all the way in town.`,
        `The taxi brought us to the office and did not ask anything for payment. Maybe he is an employee of the ferry company, or he was just incredibly nice. We came inside and encountered a customer in distress. She was crying and shouting and walking around nervously. I walked up to a worker and told her I needed to check in. I showed her my ticket.`,
        `'Check in is closed,' she told me as if she just commented on the weather. Another punch in my belly.`,
        `'Is there something we can do'?`,
        `'No.'`,
        `'Well, is there another ferry today I could take?'`,
        `'Next ferry is tomorrow, late at night'.`,
        `I quickly went through whether this is a good option in my head. I am supposed to meet my boyfriend in Cyprus. We have booked a wonderful AirBnb in Famagusta for two nights, out of which I would miss both and leave Thomas abandoned. Additionally, the port town had no accommodation options, so I would need to book a bus to some town with accommodation, sleep there, and come back. This all would cost a lot of extra money. I think I know what happened to that woman in distress.`,
        `I thanked the worker and left the office knowing I have to find an alternative. I had tears come up to my eyes but I didn’t let them fall down my cheeks - I needed to focus.`,
        `I looked into flights and found out there is a rather cheap flight from a town called Adana to Ercan International Airport that is just one hour away from Famagusta. There is also a bus to Adana that leaves in about 40 minutes, the bus station is 20 minutes away - and if I make this bus, I should make the flight.`,
        `I booked everything, informed other people who faced the same situation about my alternative in case they would make use of it. Then I started walking towards the bus station. In the meantime, I also sent a voice message to Thomas explaining what happened.`,
        `I arrived at the bus stop but it looked nothing like a bus stop. It was basically a big gas station on the side of the road. I consulted Google maps again, but this is indeed the place it wanted me at. The bus ticket in my email also did not provide any more information. I still had some time in the buffer, so I asked around maybe 5 different people, and did not get a real confirmation, just a very relaxed: 'yeeeees, don’t worry, bus stop here.'`,
        `I walked around to find any secret bus platforms hiding somewhere behind, but the further I went, the more deserted it looked there. I returned back to where I could watch the passing buses on the road. I watched them carefully and in the meantime, I tried to do an online check-in for the flight. I could not find the flight by the flight number - only an earlier flight to the same destination with a different number, which left me confused.`,
        `I was sitting on the concrete, my big backpack which became my close friend by now sitting next to me and I felt a bit alone and abandoned. Now it was five minutes past the bus departure time. I decided to call customer service.`,
        `I was told the bus was 15 minutes late and my heart skipped a beat. I still have a chance!`,
        `I was also told the bus changed the route and it should go from some grocery store parking. I asked which one exactly and the operator could not answer that. He promised to call the drivers and then call me back.`,
        `However, that took longer than those 15 minutes. He called back basically to offer a refund. In this time, I was going from emotion to emotion - stress, frustration, anger, anxiety. The gas station workers were aware of my situation and communicated with me. 'No bus?' they asked sadly. 'No bus', I shrug. 'Come sit here!' they offered.`,
        `I joined their outdoor seating. I communicated with them mostly through Google translate. By now, Thomas also found a wifi spot somewhere on Cyprus and contacted me.`,
        `Everyone was trying to help. I was doing my best too - I really have to make the flight. I do not want to be left in the middle of nowhere - I was telling myself`,
        `One guy - let’s call him Yusuf - especially did all in his power to help. First of all, he bought me a Coca Cola and forced me to accept it. Then, he informed me a taxi to the airport usually costs 130 euros (2 hours ride).`,
        `However, I had a money problem - I had no functioning bankcard. Yusuf therefore took 130 euros in cash from their cash register, counted it in front of me and handed it to me. I opened my bank account and we carefully filled in all his details. When I pressed ‘send’, my bank announced that international transfers are not possible. I sadly handed him his money back.`,
        `I blocked my only functioning card recently (I did this when I accidentally left my wallet at the hostel once) and it was not possible to unblock it from the app anymore. This happened in Istanbul two weeks ago and since then I started paying with my phone which worked perfectly. Whenever I needed more money on my international accounts, I wired it there from my Slovak account which had all my money.`,
        `I only had 70 euros combined on my other accounts now. I thought I would not need more than 70 euros for my ferry ride, not knowing I could arrive at crisis. I also expected to charge my phone at the ferry - now I had to do it from my laptop - which was also slowly losing battery life.`,
        `Yusuf called some people he knew. This whole time, I was tense and was not sure if I could trust him. He must have picked on that and wrote in Google translate: 'We are not bad people, we are just oilmen'. I chuckled. He found 'some guy' who could bring me to the airport for 70 euros that I would wire through bank transfer. I decided to trust 'some guy'.`,
        `It took a while before this guy arrived at the gas station. I kept texting Thomas, trying to scan the situation for any red flags. Half of me wants to trust Yusuf and his kindness, and the other half of me was conditioned from early childhood and mostly in my recent years about the dangers of being a woman alone and in need, being helped by strangers. I consulted every move with Thomas.`,
        `I have waited a while for 'some guy' now, and Yusuf was consoling me and reassuring me. At some point, an elderly man in a nice suit got out of a beautiful white car. 'This is him'.`,
        `The man spoke English and acted like a gentleman. Quite frankly, he looked straight out of a mafia movie, so my fear intensified. I questioned why he was going to the airport - 'I am driving a client'. His name was Mustafa. I waited for him while he smoked, walked around, bought some stuff and had a conversation. I was getting stiff - can we make the flight?`,
        `At some point, Mustafa and Yusif exchanged some words and Mustafa left without me. Yusif turned to me and explained: 'Change of plans, my friend is going to take you to the airport'. I tried to stay focused and got into a survival mode, which kept my head cold. Yusif’s friend arrived, introduced himself and I got into his car. I looked around the car and noticed the carpets of the car have airport shuttle logos. I reported this soothing fact to Thomas. 'I am in an airport shuttle!'`,
        `Few minutes later, we stopped somewhere and I was asked to get out. Then I saw Mustafa right next to us getting out of his beautiful white car. 'Now you will go with me'. I got into his car not questioning anything anymore. This car also had the same logo and I understood Mustafa is not a mafia guy but a nice gentleman working for this same shuttle. After a few minutes of ride, I looked in the back of the car and noticed there were two other passengers - a German guy and a Ukrainian woman - they were both going to the airport. I realised Mustafa had a scheduled time to pick up his customers, that is why he took his time at the gas station and then suddenly left. I let out a big sigh. But the fight was not over.`,
        `I called the flight company to see why I could not find my flight for check in. I have tried Adana airport customer service but they panicked when I asked if they spoke English. The lady at the flight company informed me about what was going on: 'Madam, you have bought the flight for November, not October.' She announced with a strong load of kindness and care in her voice.`,
        `What do we do now? Do I sleep in that town and catch another flight the next day? I feel bad now about wasting my 70 euros and all my cortisol reserves to catch a non-existing flight. Although… there was a flight to the same destination at an earlier time.`,
        `'There is a spot for you on that flight!' The lady announced a good message soon after that bad message. I have immediately rescheduled - which cost me about 30 euros. I also managed to check in immediately. For the rest of the ride, I turned off my phone to save batttery, tried to stop worrying about whether we could make it on time and chatted with Mustafa and his customers.`,
        `The Ukrainian woman was extremely nice and reassured me that the airport is tiny and the security check can be done within minutes.`,
        `When we arrived, I transferred the promised money to Mustafa. I thanked him wholeheartedly. We exchanged numbers to later verify the money has arrived. I ran into the airport and almost laughed at its size. I had about 45 minutes till take off but was waiting at the gate truly within minutes. I sat down and let out another sigh. I am almost there. I went through the hardest part and I now finally trusted I will see Thomas tonight.`,
        `Be careful what you wish for, I thought. I remembered when walking in Alanya, when I tried to redirect my thoughts into something nice, I tried to turn the loud traffic noise into memories of nice long rides (especially those at night) where you just relax and look at the lights passing you. Now I got such a ride. I also remembered how I told my mom on Crete how I love flying and since I am trying to do it as little as possible, I cherish it every time as much as I can. I could not wait till my next flight! Now that flight is happening too.`,
        `I found it ridiculous that I went through all this hassle to save the planet. I travelled by buses from Antalya, through Alanya all the way to Taşucu. I spent all this time and money just to go by plane anyway - which is sadly always the fastest and cheapest way.`,
        `Quite a humbling experience. Also humbling to show my scared and serious side to Thomas, all the people who helped me, and now all my friends and family (and perhaps other readers).`,
        `I realised I left my bag in the taxi at the port in Taşucu. There was my lunch and coffee inside. At least the taxi driver had a little tip since he did not ask for money. And if I had to lose a bag, I am glad it was that one and not the one with my laptop or other essentials. Thank God I put nothing valuable in this bag - it could easily have happened that I put my wallet there. So much luck in so much unluck.`,
        `The plane landed at around 22:30. I managed to quickly charge my phone, connect to the wifi, book a bus that went at 22:45 and communicate with Thomas. Once I was on the bus, I had no connection anymore, but we made an agreement where to meet. The bus driver was nowhere to be seen, and we were informed he was late. One hour later, we finally moved. Kind gentlemen gave me a hotspot - and I could text Thomas such that we would not worry about where I was.`,
        `Soon, everything would be over.`,
        `The bus driver dropped me off at our meet-up point. I looked around a little, and I saw Thomas in the distance. It was now around one in the night. We walked towards each other and hugged. Here, all my worries melted away. I could not believe I made it.`
      ]
    }
  ],
  signature: '18/10/2023'
}

export default post21