import post0 from '@/posts/00_balkans/00_thessaloniki_1'
import post1 from '@/posts/00_balkans/01_thessaloniki_2'
import post2 from '@/posts/00_balkans/02_skopje'
import post3 from '@/posts/00_balkans/03_ohrid_1'
import post4 from '@/posts/00_balkans/04_ohrid_2'
import post5 from '@/posts/00_balkans/05_ohrid_3'
import post6 from '@/posts/00_balkans/06_durres'
import post7 from '@/posts/00_balkans/07_athens'
import post8 from '@/posts/00_balkans/08_crete_1'
import post9 from '@/posts/00_balkans/09_crete_2'
import post10 from '@/posts/00_balkans/10_crete_3'
import post11 from '@/posts/00_balkans/11_crete_4'
import post12 from '@/posts/00_balkans/12_crete_5'
import post13 from '@/posts/00_balkans/13_crete_6'
import post14 from '@/posts/00_balkans/14_istanbul_1'
import post15 from '@/posts/00_balkans/15_istanbul_2'
import post16 from '@/posts/00_balkans/16_istanbul_3'
import post17 from '@/posts/00_balkans/17_istanbul_4'
import post18 from '@/posts/00_balkans/18_antalya_1'
import post19 from '@/posts/00_balkans/19_antalya_2'
import post20 from '@/posts/00_balkans/20_alanya'
import post21 from '@/posts/00_balkans/21_tasucu'
import post22 from '@/posts/00_balkans/22_famagusta'
import post23 from '@/posts/00_balkans/23_ayia_napa'
import post24 from '@/posts/00_balkans/24_larnaca_1'
import post25 from '@/posts/00_balkans/25_larnaca_2'
import post26 from '@/posts/00_balkans/26_paphos_1'
import post27 from '@/posts/00_balkans/27_paphos_2'

const postsBalkans = [
  { name: 'Thessaloniki 1', path: 'thessaloniki_1', data: post0 },
  { name: 'Thessaloniki 2', path: 'thessaloniki_2', data: post1 },
  { name: 'Skopje', path: 'skopje', data: post2 },
  { name: 'Ohrid 1', path: 'ohrid_1', data: post3 },
  { name: 'Ohrid 2', path: 'ohrid_2', data: post4 },
  { name: 'Ohrid 3', path: 'ohrid_3', data: post5 },
  { name: 'Durrës', path: 'durres', data: post6 },
  { name: 'Athens', path: 'athens', data: post7 },
  { name: 'Crete 1', path: 'crete_1', data: post8 },
  { name: 'Crete 2', path: 'crete_2', data: post9 },
  { name: 'Crete 3', path: 'crete_3', data: post10 },
  { name: 'Crete 4', path: 'crete_4', data: post11 },
  { name: 'Crete 5', path: 'crete_5', data: post12 },
  { name: 'Crete 6', path: 'crete_6', data: post13 },
  { name: 'Istanbul 1', path: 'istanbul_1', data: post14 },
  { name: 'Istanbul 2', path: 'istanbul_2', data: post15 },
  { name: 'Istanbul 3', path: 'istanbul_3', data: post16 },
  { name: 'Istanbul 4', path: 'istanbul_4', data: post17 },
  { name: 'Antalya 1', path: 'antalya_1', data: post18 },
  { name: 'Antalya 2', path: 'antalya_2', data: post19 },
  { name: 'Alanya', path: 'alanya', data: post20 },
  { name: 'Tasucu', path: 'tasucu', data: post21 },
  { name: 'Famagusta', path: 'famagusta', data: post22 },
  { name: 'Ayia Napa', path: 'ayia_napa', data: post23 },
  { name: 'Larnaca 1', path: 'larnaca_1', data: post24 },
  { name: 'Larnaca 2', path: 'larnaca_2', data: post25 },
  { name: 'Paphos 1', path: 'paphos_1', data: post26 },
  { name: 'Paphos 2', path: 'paphos_2', data: post27 },
]

export default postsBalkans
