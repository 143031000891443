import { PostProps } from "@/types"

const post0: PostProps = {
  key: 0,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Thessaloniki',
  title: 'Do not judge a city by its city center',
  content: [
    {
      type: 'text',
      value: [
        `I pretty much forgot about the 1 hour time difference between Slovakia and Greece, so when our plane started to descend dramatically, I was surprised we were almost there. I looked out of the window, and got welcomed by a dark sky and raindrops running down the window. The plane had landed and people started clapping - a custom that is becoming less and less common but I always have the urge to start clapping. I was slowly realizing I am in Greece now, about to embark on a 2 months long digital nomad travelling experience. The applause for the pilot alongside this realization made me a bit emotional. As I travelled to the hostel, I was thinking it is quite funny I am here. What the hell am I doing? It feels so random! I am really in this new town, about to do something I thought I was made to do. And soon I would find out if I was right.`,
        `For my first proper hostel experience, I chose a very friendly-looking place called Jetpack Alternative Eco Hostel. The host was very nice and talkative. Soon enough I was immediately taken under the wings of an Italian girl called Clara, who has been volunteering at the hostel, thus staying there for free (if this caught your attention, check out the website WorldPackers!) Clara showed me the room and introduced me to the other girls. It was now about 11 o'clock and I wanted to take a shower, but standing in the middle of the room and getting stuck in a conversation now sounded like a much better idea.`
      ],
    },
    {
      type: 'text',
      value: [
        `The next morning, I occupied the desk spaces on the first floor of the hostel. Working there was a very smooth experience - perfect Wi-Fi and enough silence for occasional calls. The host even made me a double Cappuccino. I had no stress about working at this place for a week. During my lunch break, I took a walk in the direction towards the sea. There was no sea but only an industrial port and a promenade. If you want to go to the beach in Thessaloniki, you have to take a long bus ride, as I found out. I admired the well-fed stray dogs sleeping at the port.`,
        `My excited state and the noise from the street (to which I needed to accustom, coming from the countryside), made me sleep pretty bad at night. I fixed this by taking a very nice meditation and napping session after work. Later in the evening, me and Pauline, a new volunteer girl from France went to walk to the port to watch the sunset. It was a bit blurred, because of the clouds. Walking through the city, I am realizing something. 'Do you also feel like there was no real urban planning in this city?' I asked Pauline. To me, the buildings seemed so dense, grey, heavy, very close to each other... and to be honest... quite ugly. I felt like I don't understand Thessaloniki, I cannot see the real soul of the city through these dense buildings. As if the city was in a big cloud. I did not allow myself to feel disappointed - I knew there was more to the city - I just didn't know yet what it was.`,
        `After coming back to the hostel, we picked up more people and went to get some delicious ice cream. We ate it while taking yet another walk to the port (my third on this day).`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: "/images/00_balkans/post0/1.jpg",
          description: ['', 'grey blocky architecture of Thessaloniki'],
          color: 'blue'
        },
        {
          src: '/images/00_balkans/post0/2.jpg',
          description: ['one of the stray dogs relaxing at the deck'],
          color: 'pink'
        },
        {
          src: '/images/00_balkans/post0/3.jpg',
          description: ['sunset gazing'],
          color: 'pink'
        },
        {
          src: '/images/00_balkans/post0/4.jpg',
          description: ['the famous white tower'],
          color: 'blue'
        },
      ]
    },
    {
      type: 'text',
    value: [
        `That night, I slept delightfully. The next day after work, I decided to take a walk on my own. The volunteer girls were busy with work, just as I finished mine. It was slightly raining outside, not too dramatically. I went to the port (of course), then I took a walk from the White tower, dove into the dense city, uphill, towards the Rotunda and from the Rotunda higher up. It surprised me just how steep it got. Suddenly, the buildings were getting less tall, more cute, colorful, and so charmingly scattered around. I was walking through nice little staircases and started noticing cute cafés. At each and every one of them, I thought: "I'd love to go here!"`,
        `As I was arriving at the ancient city gate, I felt like I saw Thessaloniki so much more clearly. I was admiring the old walls and the acropolis and dropping my jaw at the view. I could see everything from here. The dense and flat fully-built city center below me, the industrial port, the White tower, some more monuments, the sea, the mountains on the horizon. Although it was still raining, to me it felt like the cloud of Thessaloniki was clearing up. I felt like now I could finally understand it.`,
        `I started reading some information boards about history, but gave up soon as it was written in a very boring bad school textbook kinda way. I took a walk around and was surprised to see behind the acropolis, the city ends and the forest starts. I didn't realize the city was that narrow. I guess my favorite way to travel is to know the bare minimum about the destination, get surprised and uncover everything while you are there - this makes it feel like an adventure!`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post0/5.jpg',
          description: ['the colorful and hilly area above the city'],
          color: 'pink'
        },
        {
          src: '/images/00_balkans/post0/6.jpg',
          description: ['view on the Church of Saint Paul'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post0/7.jpg',
          description: ['view on the sea and myself, taken by kind strangers'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post0/8.jpg',
          description: ['one of many cute restaurants seen while hiking down the hill back home'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `It was getting a bit late at this point, so I started to walk back to the hostel. I wished so badly to just get lost in these cute little hilly streets (oh and I did). The rain was warm and the rainy streets seemed so romantic to me. I was having a blast, although it is good I made my way back home. From safety reasons, but also because I don't think I had much more capacity for this beauty anymore.`,
        `Clara, the warm wonderful Italian she is, ran to hug me saying she missed me. We went to the rooftop terrace at the hostel. We spent a nice evening there with the hostel people, eating spaghetti and sharing nice conversations.`,
        `On my second full day at Thessaloniki, I am thinking about balance. I want to socialize, even if it means taking the same walk three times a day, but I also want to explore the city as much as possible (which might mean I need to separate on occasions). It is my first real long-term solo travel and I want to do everything right and not miss any opportunity.`,
        `If I only knew what would await me the upcoming days... I wouldn't spend a second worrying about this balance.`,
      ],
    },
  ],
  signature: '04/09/2023 - 06/09/2023'
}

export default post0