<template>
  <div class="not-found-page">
    <div class="not-found-content">
      <div class="four-oh-four">404</div>
      <div class="oops">oops...</div>
      <div class="not-found-text">The page you are looking for does not exist.</div>
      <router-link class="go-home-text" to="/">Go back to Home</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  created () {
    this.$store.commit('SET_NOT_FOUND', true)
  },
  beforeUnmount () {
    this.$store.commit('SET_NOT_FOUND', false)
  }
}
</script>

<style scoped lang="scss">
@import '../main.scss';

.not-found-page {
  font-family: 'RozhaOne';
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $sand;
  border-radius: 14px;
  width: 100%;
  height: 96vh;
  padding: 0 10px;
}

.oops {
  color: $dark-brown;
  color: $blue;
  font-size: 80px;

  @media only screen and (max-width: 500px) {
    font-size: 40px;
  }
}

.four-oh-four {
  font-size: 250px;
  line-height: 250px;
  color: $rose;

  @media only screen and (max-width: 500px) {
    font-size: 100px;
    line-height: 100px;
  }
}

.not-found-text {
  color: $dark-brown;
  color: $blue;
  font-size: 20px;
  padding-bottom: 10px;

  @media only screen and (max-width: 500px) {
    font-size: 16px;
    padding-bottom: 0px;
  }
}

.go-home-text {
  color: $dark-brown;
  color: $blue;
  font-size: 20px;

  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
}

</style>