import { PostProps } from "@/types"

const post11: PostProps = {
  key: 11,
  x: '41.5%',
  y: '84%',
  zIndex: 120,
  location: 'Crete',
  title: 'Living on the beach',
  content: [
    {
      type: 'text',
      value: [
        `This day was full of chores. That is why we started it with a slow breakfast again. We packed. Kostas - our host helped us with luggage - poor man had to pick up my 20kg backpack and struggled to not pop his eyes out. We arrived in Heraklion - Amoudara and were happy to see our keys on the counter despite being two hours before check-in. We dropped everything off, went to get some gas - we still do not know what goes in the car but gas station people know all the rental cars very well and always help out.`,
        `Then we returned the car to the airport. Driving in Heraklion is kind of crazy and people seem a bit more nervous here from the traffic. We took the bus back, then unpacked, and went to do groceries.`,
        `We still managed to make the late afternoon look like a vacation. We went swimming and scanned our new environment. After that, we had dinner in a taverna (Greek traditional restaurant). I had a Cretan pizza, which resembles a Greek salad - but on a pizza. It was amazing. My mom had moussaka, sort of a cake with lamb, aubergine and a creamy sauce. We had very nice conversations. We finished everything off with my favourite Greek drink - Ouzo. They brought it in a big glass with ice, together with some caramelised apricot and orange.`,
        `The next day was a heavily lazy day - and every minute of it was perfect. We had a really slow breakfast on the balcony and spied on our neighbours getting in the cars and going to work or school. We returned to bed and napped, relaxed, and read books.`,
        `Around noon, we moved to the beach and spent the entire day there. We walked towards both sides, to see what was out there. We swam and floated in the water. We laid down in the sand. I took a long walk to find some ice cream while my mom relaxed and waited for me. By the time I was back, the ice cream was melting, but I had to eat it slowly because I wanted my mom to taste it too!`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post11/1.jpg',
          description: ['mom and some ouzo after the dinner'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post11/2.jpg',
          description: ['enjoying the lazy beach day'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post11/3.jpg',
          description: ['beach nap', 'we are not in a hurry today'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post11/4.jpg',
          description: ['the sun setting behind the mountains'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `As the sun was getting ready to set, we got a bit colder and did not go to the water anymore. We laid down, read books, and I slept for a bit. We got a cute visit, a beautiful puppy, which could not control its excitement. The owner was not too happy, he said: ‘Careful, she steals from people’. We watched them play and were entertained by the dog getting constantly distracted by every single input and by its clumsiness.`,
        `We observed the sun setting and sinking into the mountains. Then we walked back to our apartment which was about 3 minutes from the beach. We made amazing pasta for the evening, and a great amount too. That’s when my mom said: ‘you are an amazing vacation partner, but you eat too much!’`
      ]
    },
  ],
  signature: '24/09/2023 - 25/09/2023'
}

export default post11