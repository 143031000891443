import { PostProps } from "@/types"

const post24: PostProps = {
  key: 24,
  x: '88%',
  y: '89%',
  zIndex: 110,
  location: 'Larnaca',
  title: 'The surreal salt lake experience and the NPC cat',
  content: [
    {
      type: 'text',
      value: [
        `Our next location also started with a little disaster.`,
        `We arrived in Larnaca late in the evening. I was getting a little bit stressed, because our Airbnb host was not responding to our messages, and we had zero information about checking in. Thomas suggested we stay calm and figure it out when we arrived at the address.`,
        `But what address? When we made it to the address marked in the Airbnb app, it looked nothing like what we saw in the pictures. We walked around, confused. At some point, we got desperate and rang a bell at some of the residents’ houses, but we got no answer. We caught a woman driving towards a building with apartments and we went to talk to her in the garage. She said there was no chance there could be an Airbnb, this was all residential. However, she did tell us that the street name could point to another place.`,
        `We came to that other place but it looked even less like the cute holiday house in the picture. There was a big intersection with shops around. Again, we made a round to make sure we did not spot Mike’s magical house (Mike was the host’s name). Eventually, we talked to some people at a lottery shop - the only place that was open there.`,
        `They could not help, but asked the worker to give us the wifi password. We tried our best to contact Mike. It was strange, he had another listing on Airbnb, with the same picture and another address. We did a Google image search and found some old links to the marketplace, where this house was listed but with the second address. Someone was criticising Mike for giving the wrong address in the reviews and he claimed he had corrected it. So perhaps he made a new listing with the new address, to which he checks emails and messages, and forgot the second one - thus ignoring the messages there. We really wanted to believe Mike was not a scammer, just a bit… illiterate.`,
        `We made another walk around, and confirmed there was no chance Mike’s house was anywhere around here. We returned to the lottery shop, booked a private room at a hostel, and a lottery ticket - if we were so unlucky, maybe now we would get lucky.`,
        `We made it to the hostel - completely exhausted from carrying the luggage all around Larnaca. We picked up a falafel at a closeby veggie fast food place. We ate it there and could now laugh about the whole situation. Then we ceremonially scratched off all 24 items of our Christmas lottery ticket - but it was not so merry.`,
      ]
    },
    {
      type: 'text',
      value: [
        `The next day, we took it easy. After work, we did proper groceries for the whole week in a big proper supermarket (we missed these in Ayia Napa). We made a nice dinner, then walked towards the beach and lay on the beach chair and talked. On the way back, the falafel guys waved at us - now we were basically friends.`,
        `We were granted a better room at the hostel - a much bigger and fancier one, with our own balcony! The hostel was pretty cool, with a very relaxed colourful interior - it was not trying to be something on purpose. It just was.`,
        `We met the boss when checking in, but we hadn’t seen him since. Instead, there was this other guy, who was rather shy, and looked like a homeless guy. It seemed like he lived there, spent all his time there and worked there - for exchange for a home. He was pretty nice and polite and kept the hostel nice and clean. When he was not working, he was lying on the couch, watching some videos on his phone for hours.`,
        `On Tuesday, we were full of strength again and had more exciting plans. After work, we made our way to the salt lake. I imagined we would go and see water - that it would be more lake than salt. But it was basically only salt.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post24/1.jpg`,
          description: ['Larnaca Salt Lake'],
          color: 'pink',
        },
        {
          src: `/images/00_balkans/post24/2.jpg`,
          description: ['this is not ice!'],
          color: 'blue',
        },
        {
          src: `/images/00_balkans/post24/3.jpg`,
          description: ['the gorgeous sunset on the lake and the mountains in the background'],
          color: 'blue',
        },
        {
          src: `/images/00_balkans/post24/4.jpg`,
          description: ['the zoom in on the salt crystals'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `It was a really bizarre sight. We entered ‘the lake’ and it felt like we were walking on a layer of ice. But no - it was a thick layer of beautiful salt crystals! I had to keep convincing my brain that it was indeed not ice. We walked around, gasped, and played. Some places had a pinkish colour, others blueish, probably depending on the minerals that were just under. At some point, we got close to the middle of the lake and it started getting a bit more soft there. I dug with my hand under the salt and found clay. It was almost black and it felt satisfying to play with it.`,
        `Here, an Australian guy walked up to us and was curious about what we found there. It was just him and us on the lake. I showed him my dirty hands. We had a small talk and then all 3 of us decided to walk to the other end of the lake. It was quite the hike! At the edge, there was a little mountain that me and Thomas decided to climb, but the Australian guy chose to go a different way. He was worried about snakes - perhaps he has some scary stories from Australia.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post24/5.jpg`,
          description: ['the sunset'],
          color: 'blue',
        },
        {
          src: `/images/00_balkans/post24/6.jpg`,
          description: ['playing with the clay'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `For a while, Thomas and I walked on the land. We planned to go to the mosque on the other side of the lake. It was quite a desert-like environment, but luckily we met no snakes. We saw something more welcoming - a flying dry bush - a tumbleweed! This made Thomas’ day. Apparently he had seen it in (especially western-like) movies but I only learned of its existence now.`,
        `Eventually, we decided to enter the lake again - this seemed like the shortest way - straight towards the mosque. We walked on and eventually, we started to sink. The crystals were cutting our feet. At some point, it made no sense to wear sandals anymore and we both walked barefoot. Our feet became bloody and black and salty at the same time. It hurt, but we kept thinking we found a tactic that would prevent the sinking. We tried everything but everything turned out not to work. As a response, Thomas got desperate, I started laughing.`,
        `Somehow, we kept going. It was getting dark now and the mosque was getting closer and closer. I could not stop thinking about washing my dirty and bloody feet at the mosque’s fountain.`,
        `Suddenly, we were out of the lake. But it was not the end to our pain - the grass was full of thorns. We did not want to put our shoes back on, as our feet were horribly dirty. Instead, we tried to forget pain as a concept at this point.`,
        `We were now at the back of the mosque, but sadly, there was a fence we could not cross or climb over. We were confused about which way to go around to get on the road. Until a cute cat appeared and led us the right way. It was like an NPC who came to show us the way. A magical cat. Now it was completely dark so we only saw what was illuminated by the night sky. The character of our walk was comedic, as our feet were hurt.`,
        `Eventually, we made it to the mosque - but found out the whole outside area was locked - so no fountain for us. We had to dry clean our feet and spared last sips of water to wash our hands - especially mine.`,
        `Finally, we ate the wraps we brought for dinner and things seemed a little lighter again. More and more cats were popping out (or should I say spawning?) and hoped for a bit of our food. We did not dare to feed them, as we thought they might start a fight. In the end, there were maybe 30 of them and I got an irrational fear that they might gang up on us.`,
        `When we decided to walk on, we slowly shook them off, but some of them stayed for a good 10 minutes.`,
        `It was pretty hard to hitchhike on the big road that we were on, and the bus was not going that soon, so we decided to put on our shoes and walk home. I promised Thomas we would be there in one hour if we walked fast. We kept a crazy fast pace. So fast it gave us another spring of energy. We made it home and looked at the clock. It took exactly one hour.`
      ]
    },
  ],
  signature: `23/10/2023 - 24/10/2023`
}

export default post24