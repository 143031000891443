import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import JourneyBalkans from '@/components/JourneyBalkans.vue'
import JourneySoutheastAsia from '@/components/JourneySoutheastAsia.vue'
import Post from '@/components/Post.vue'
import NotFound from '@/components/NotFound.vue'

import postsBalkans from './posts/00_balkans/posts'
import postsSoutheastAsia from './posts/01_southeast_asia/posts'

const generatePostRoutes = (journey: string, posts: any[]) => {
  return posts.map(post => ({
    path: `/${journey}/${post.path}`,
    component: Post,
    props: { post: post.data }
  }))
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/balkans/thessaloniki_1',
  },
  {
    path: '/balkans',
    redirect: '/balkans/thessaloniki_1',
  },
  {
    path: '/balkans',
    component: JourneyBalkans,
    children: generatePostRoutes('balkans', postsBalkans),
  },
  {
    path: '/southeast_asia',
    redirect: '/southeast_asia/bangkok_1',
  },
  {
    path: '/southeast_asia',
    component: JourneySoutheastAsia,
    children: generatePostRoutes('southeast_asia', postsSoutheastAsia),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
