import { PostProps } from "@/types"

const post4: PostProps = {
  key: 4,
  x: '16.5%',
  y: '12%',
  zIndex: 110,
  location: 'Ohrid',
  title: 'Unexpected adventure',
  content: [
    {
      type: 'text',
      value: [
        `This Thursday was basically a Friday for me, since tomorrow is a public holiday in Slovakia. Once I got off work, I met Magdalena and Daniel at the hostel. We made a plan to hitchhike to the Monastery of Saint Naum.`,
        `The hitchhiking was a bit of a struggle, Macedonians smiled, waved, interacted, but didn't stop. To make things easier, Daniel returned to the hostel to pick up his motorbike and promised he would meet us there.`,
        `After some time, we have finally stopped a car. We were thrown off by the driver's strong Australian accent. We learned that he is Macedonian but lived in Australia for 24 years. He lives off investment properties and now helps out friends to keep himself busy. He said he would drive us all the way to the monastery, but he promised a friend to help paint his house. We had a wholesome conversation and he dropped us off as far as he possibly could.`,
        `Now we were only 15 minutes from the monastery, but there were literally no cars. This village was really deserted and we saw maybe one car each 5 minutes. After several failed attempts, we accepted the defeat and hopped on a taxi.`,
        `The road went through the slope of the mountains, it had twists and turns. We could see the lake on the right hand side, the sun was getting ready to set down. A motorbike has approached the back of the car and we have realized, this must be Daniel! We tried to wave to him but he was too busy with watching the road... And the view.`,
        `We have arrived and walked into the monastery. I spent considerate amount of time in there, admiring the fresques. My favorite one depicted the 7 pupils of Methodius, one of them being Naum who has built the monastery.`,
        `When I got out of the temple, the sun was already set, but the view was still beautiful.`,
        `We had a similar moment of quiet appreciation as we had yesterday.`
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post4/1.jpg',
          description: ['the dark and mysterious interior of the monastery'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post4/2.jpg',
          description: ['the mural of the pupils of Methodius'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post4/3.jpg',
          description: ['the view from the monastery'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post4/4.jpg',
          description: ['the lake after the sunset'],
          color: ' blue',
        },
      ]
    },
    {
			type: 'text',
			value: [
				`At some point, I realized that all the villages along the lake on the left were already in Albania. The closest one was Pogrodec. It seemed pretty reachable, I estimated less than one hour by walking. We checked with the scale on Google maps and I was right. It seemed to be about 6 kilometers away. I got a spontaneous idea to go there and my enthusiasm was matched by Daniel and Magda. I was off work tomorrow, so I did not have to be concerned about getting home in time.`,
				`Daniel took his motorbike. Magdalena and I walked through the border controls and soon enough stoped a not so talkative Albanian guy.`,
        `We made an agreement to meet Daniel in front of Hotel Pogrodec. Pogrodec is a nice town, but far from as jaw-dropling as Ohrid. The Australian driver actually mentioned Pogrodec and explained that most of Albanian tourism is focused on the sea coast area anyways.`,
        `When Daniel arrived, we walked around the city and soon found a place to eat. It was a reasonably nice place, so 3€ pasta came as a surprise, even in Albania.`,
        `The dinner was nice, Daniel and Magdalena shared some good stories from their travels to more 'exotic' countries like India and Costa Rica. I loved listening to them and asked many curious questions. I cannot wait to travel more, apart from the US, I haven't really been outside Europe.`,
			],
		},
		{
			type: 'text',
			value: [
				`It was sometime between 9 and 10 when we decided to go back. We were slightly worried about how it would play out. Still, we didn't want to get a place in Pogrodec but try our best to get to Ohrid.`,
				`We stopped a car who brought us to the border, from where we wanted to find a car to Ohrid. Daniel saw were are safe and left to get his motorbike. The driver didnt't speak English or any of our languages, so he used Google translate while driving to communicate.`,
				`At the border, we caused some surprise when the policemen heard we want to hitchhike all the way to Ohrid. It seemed like throughout their career, they haven't seen this. We were realizing that maybe it is not so easy or smart to hitchhike here. We got in a car of a very friendly and talkative Macedonian, who couldn't bring us to Ohrid but at least to his village, somewhere half-way to Ohrid. It seemed like he was friends with the policemen and he was hanging out there talking to them while crossing.`,
        `He kept saying: "You crazy girls!"`
      ],
		},
    {
      type: 'text',
      value: [
        `I am realizing we never introduced, so we will give him a fictional name: Jovan. Jovan stopped on the side of the road in his village and was willing to wait with us to see if there is any sign of life, or possibly a car. He was totally right in that this place is completely asleep and no one seems to have a reason to go to Ohrid at this hour. He said he could leave us, but also that he has a bed and a coffee for us. He also owns some guest appartments, that are not yet on the market but almost ready.`,
        `Magda and I have agreed it is probably best to accept his offer. He has gained our trust with his humor and kindness.`,
        `We drove to his place. There we saw the appartments and a garden with a camper. We sat down in the garden table and hang out. Jovan offered some Rakia and Ouzo. He saw we were cold and offered some jackets he found in the camper. He made us feel comfortable.`,
        `Jovan's kids and wife were at that moment in Skopje, but it wasn't clear why. He is a very friendly person, so when he gets lonely, he would go to Albania to drink coffee and talk to some people there. That's what he did today. He had an interesting life story, he build the biggest casino in Skopje, he worked every day for several years to make that happen. He owned a company for car cleaners and developped products with the best pH on the market back then. He now makes some wonderful alcohol he let us taste and he works a lot on his new rental appartments. This man was full of interesting stories and funny anecdotes.`,
        `The most interesting part is that he also used to be a policeman. It was in the 90s - when Albania was indeed not a safe country. When he talked about it, he really wanted us to know how unsafe Albania is for hitchhiking, but he also didn't want to scare us. He kept saying "Oh, you don't want to know what I know!". He said it so many times that our interest grew and we made him tell us. He was probably just raising tension for the effect. He talked about the human trafficking mafia in Albania. Several specific stories of people from this area who went missing. He also told us the story of the Yellow house in Kosovo. Talking about these topics in the dark silent garden gave it the feeling of how we used to tell scary stories as kids during a sleepover - but with the important difference that these were real.`,
        `Jovan made a hotspot so that we can text Daniel not to wait for us, and Magdalena had to inform Viljams (a Latvian guy) that their plans this evening have to be cancelled. Viljams then mentioned to Gyoko (the hostel owner) that we are in this random village. Gyoko joked about going to pick us up, but regretted it when Viljams texted it to us as a real deal. As a result, Gyoko had to 'keep his word' and really go pick us up. Jovan called him to explain the directions.`,
        `We thanked Jovan for his kindness. Before we left, he had to show us the appartments, he was pretty proud of it - rightfully so. We said goodbye and sat in Gyoko's car. Gyoko said he picked up a lot of hostel people from all kinds of places, but in his life, he never went to this random village. Within 20 minutes, we were at the hostel, with people in the living room awaiting to hear our story.`
      ]
    },
    {
      type: 'text',
      value: [
        `I stayed up quite late, hanging out in the living room talking to people. I thought I already experienced my most bewildering time of the day. I did not know I was going to get into a conversation with someone about Illuminati. I got very detailed descriptions about how they operate, and what kind of ceremonies they do. It was quite interesting, because last time I heard about Illuminati was around the time people started discussing symbolism in Rihanna's Umbrella music video. I was never really interested in it and definitely did not know anyone directly who had such a deep belief in it and such a detailed knowledge. I guess hostel lifestyle offers this too.`
      ]
    },
  ],
  signature: '14/09/2023'
}

export default post4