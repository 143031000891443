import { PostProps } from "@/types"

const post15: PostProps = {
  key: 15,
  x: '62.5%',
  y: '12%',
  zIndex: 120,
  location: 'Istanbul',
  title: 'Slow pace of discovering the soul of the city',
  content: [
    {
      type: 'text',
      value: [
        `I have barely done anything on day 3 in Istanbul. I got sick, at the same time, I got my period and my body was (not so subtly) hinting it needs a lot of rest. I took a little stroll in my neighbourhood but I didn't go far.`,
        `So for today I'd just like to describe some insights and curious things I have noticed about Istanbul.`,
        `First of all, this is a city of men. I feel like wherever I went, 90% of the crowd were men. They operated all the shops, they drove all the cars, they chatted and laughed together while taking a break with some shisha and Turkish tea. I was wondering a lot about where all the women were, and asked some people I talked to. The most sensible explanation is that there is quite a traditional model of society here, meaning, women stay at home with children and men go to work, to the streets. This difference can be seen in the mosques too - the main part of the mosque can be used by men during the prayer time, and an isolated, much much smaller part is dedicated to women. This is probably because women stay at home and pray there with the kids.`,
        `Another observation is that cars own the city. The public transport is terribly packed (although this is probably the case for most such mega cities). However, the cars go through even the smallest of the streets, also where there are crowds. However, they don't slow down, they claim the road by honking, and go through super fast. People jump off the road to save themselves and the drivers are all happy. Sometimes, this was a little annoying. I heard honking about 100 times a day.`,
        `A thing that makes this city really special for me, is that it seemed as if every street had its mosque - that's how many there were. Some were small, others bigger and more significant and a few of the most significant ones in the world. 5 times a day, you could hear singing through the speakers of many many mosques in your radius. The sound got a bit distorted due to the speakers, but also because there were so many sources of the singing. It always got so wonderfully strange when there was the time of the prayer.`,
        `If I say that every street has its mosque, I should also say that every street has like 3 cats, at least! So this place is a paradise for cat lovers.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post15/1.jpg',
          description: ['strange art exhibition I randomly encountered'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post15/2.jpg',
          description: ['typical coloful houses in Balat area'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post15/3.jpg',
          description: ['one of many colorful cafes'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post15/4.jpg',
          description: ['I saw a plenty of beautiful street art'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `On day 4, I managed to do a bit of a bigger walk. I found a house full of really strange art. Then I moved towards the Balat area, which was pretty close to my hostel. This is a wonderfully colourful area, tourists love it. There are countless incredibly cute cafes and art guilds. Whoever is in Istanbul for a few days, should go have a look at this place. I managed to walk towards the Insta-worthy colourful staircase. This was a big photoshoot place. I took my time and got some pictures taken by some kind strangers, and took some pictures for others. On the side of a staircase, there was an equally colourful cafe. I was surprised it was quite empty - there were more cats than people. I drank some tea and ate San Sebastian. I read my book, wrote my diary, and replied to friends and family. This colourful place was perfect for some self care. When I was ready to pay, I realised I didn't have a wallet and walked in panic to my hostel. There was no need to panic because my wallet was waiting there for me. So I took yet another walk to the cafe, paid, and went home for good.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post15/5.jpg',
          description: ['landscape', 'big'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post15/6.jpg',
          description: ['landscape', 'big'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post15/7.jpg',
          description: ['the Instagram-famous colorful staircase'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post15/8.jpg',
          description: ['the cafe on the staircase', 'and a little furry friend'],
          color: 'blue',
        },
      ]
    },
  ]
  ,
  signature: '03/10/2023 - 04/10/2023'
}

export default post15