import { PostProps } from "@/types"

const post1: PostProps = {
  key: 1,
  x: '28.5%',
  y: '20%',
  location: 'Thessaloniki',
  title: 'Free walking tour obsession',
  content: [
    {
      type: 'text',
      value: [
        `On Thursday, a German guy named Gregor arrived at the hostel. He inspired us to book a free walking tour about Thessalniki and its history. If you don't know about this concept yet, hear me out: Google a free walking tour when you are visiting a new city. There are plenty, especially in Europe. The guides are usually really educated and passionate about their topic. Gregor said he always does this at the beginning of his trip, as these guides usually have the best recommendations for the city. And in theory, it is free - you just give a 'tip' and decide for yourself how much that should be.`,
        `The meeting point was at the White tower. Our guide, Evan, was a young student, who knew impressive things not just about Thessaloniki. He surprised me by his knowledge about the upcoming parliamentary elections in Slovakia, and he was even aware of the most recent polls (more so than me to be honest). We were in good hands. And since we met at the White tower, let me tell you a disturbing fact I learned about this monument. In the 19th century, the Ottomans used it as a prison for the rebellious, and massacred them inside. It was actually called the Blood tower back then, because the blood was running down through the windows. It received its more friendly name White tower, since a prisoner once painted the tower white in exchange for his freedom.`,
        `The history of Thessaloniki is very diverse and turbulent. At moments, very dark, then insignificant, then glorious and wonderful. During the tour, I got clarity on the missing urban planning of the city. As Evan was pointing out, the sidewalks were too narrow for a wheelchair or a stroller. There is basically no tram or metro, only very scarce busses (which Google maps isn't aware of, so we just chose to walk everywhere). The parking is messy. There are too few crosswalks and the green light is only on for 5 seconds at a time. Navitating the city centre as a pedestrian can be enraging. And finally, those messy ugly buildings.`,
        `And why so? Greece went through some devastating times in the 20th century. The Greek-Turkish war, the Second World War, the civil war, and to top it all off, a devastating fire which burned down the majority of the city centre. The economic crisis caused migration from the countryside to the two biggest cities: Athens and Thessaloniki. Moreover, the agreement after the war in 1922 meant all Turkish people living in Greece had to move 'back' to Turkey and Greek people living in Turkey had to move to Greece - which was about 800000 more people. Thessaloniki could not handle the housing crises. What happened is that land owners let engineers build property on their land for an exchange of a few flats - depending on the agreement. The government turned a blind eye, letting the problem solve itself. This meant, there was basically 0 regulation from the government. The builders needed to build as fast as possible, and they built to the last centimeter of the land provided. There was no space and time to think about the width of the streets, the public transport, the architecture and style - leaving the city center looking all messy. However, this intense building period saved Thessaloniki and its people from the prolonging crisis.`
      ],
    },
    {
      type: 'text',
      value: [
        `Another mind-blowing thing I learned is that Thessaloniki has a very significant Jewish history too. There was one of the most ancient Jewish community in Europe. Before the Second World War, they made up two thirds of the population. But more about this later. I decided not to talk about the Hellenic, Roman, Byzantine and Ottoman eras, as this turned out not the thing I was most curious about. I did find it interesting that people kept discovering historical sites as they planned to build new buildings in those areas - and they wished they wouldn't. So now the new buildings live in symbiosis with protected ancient sites right next to them.`,
        `We left the tour full of amazement for this city. Afterwards, we sat at a cute authentic Greek restaurant and had wonderful dinner and wine. Greeks eat dinner late, at least after 9 and definitely till at least 12. We started following their style and really took our time and savoured every bite and sip.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post1/1.jpg',
          description: ['arch of Galerious, one of the city`s highlights'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post1/2.jpg',
          description: ['Rotunda with the preserved minaret'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post1/3.jpg',
          description: ['the contrast between the functionalist and eclectic buildings:', 'this is seen all over the town'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post1/4.jpg',
          description: ['another example of the contrast'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Friday evening came and that meant going out! First, Clara and I exchanged very nice back massages - one thing that for sure bothers me about my work is the backpain - and my reluctance to finally make time for frequent exercise, especially when travelling. Afterwards, me, Pauline and Gregor took a walk, you guessed it, to the port, to see the sunset from the side of the White tower. This sunset was a lot more clear, since the weather turned very nice on this day. We gazed into the water long after the sun has 'sank' and it was a long while before anyone proposed getting back to the hostel. It was just so nice there! Gregor and I left for dinner and Pauline and Clara still had to take care of some hostel check-ins. The highlight of the dinner was the shots we got on the house. The warmth and the sweetness of the liquor was a nice cherry on top of a tasty dinner.`,
        `At the hostel, we met up with Pauline and Clara and two more girls joined us - Branka from Serbia and Cassandra, who has a complicated Greek-German-American background. I was happy they were joining as I wished to spend more time with them still and to get to know them better. We chose a fun bar at the upper end of the main square. The bar was completely full and so people chilled on the street and sat randomly around - it had a very nice warm summer night vibe. The drinks opened up some funny and ridiculous conversations. After one drink, we moved to another bar closer to the hostel. Here, the drinks shifted the conversations to more deep and profound topics. I was feeling really great but I know I wasn't believable, since I was getting really tired and struggled to keep my eyes open. Around 4am, we decided to return to the hostel.`,
        `I wish I could say that the weekend meant more sleep for me, however, I had another walking tour I booked for 10am and there was no way I was missing that. Now I am obsessed with this quirky city. After some 4 hours of sleep, I rushed into the shower, packed my stuff pretty chaotically, and ran (literally) to the tour. I was supposed to be 10 minutes late but I made it on time. My dramatic run turned out quite useless because the last people arrived around 10:20 anyways.`
      ],
    },
    {
      type: 'text',
      value: [
        `I was alert and awake, holding onto my double latte, and eating every piece of information Thenia provided us with. Her tour was captivating. Half of the tour was about the roman history, the other half (more difficult to tackle) about the Jewish history. She presented everything in a tackful and sensitive way.`,
        `The importance of the Jewish history in the city was unquestionable. Jews have built the city and made it important again after a silent period in the history. From time to time, we were shown that besides the harsh functionalist buildings, there were beautiful eclectic ones - those jewels were built by jewish builders.`,
        `The story about the Jewish cemetery gave me goose-bumps. There used to be a large, very significant cemetery inside the town. The city wanted to build there - the Jewish community paid a lot of money to conserve the cemetery. However, during the WWII, the city misused the situation to still break the whole cemetery apart. This way, they both got the money and the cemetery. Its marble tiles were used to build pavements and benches in the city. As we were listening to this, we were sitting on one such bench. We stood up and noticed the hebrew engravings on it.`,
        `I was tearing up at the end of the tour. Thenia talked about the the deportation of the jews. We all know these horrible facts from the history, but Thenia really made us imagine how the whole progressive squishing of their freedom leading up to the deportations must have felt. She put real human stories into the facts. The square Eleftherias is where horrible acts before deportation and the deportation itself happened. The square is now a parking lot. The city is, however, promising to cancel it and build a monument to remember the jewish suffering.`,
        `She ended the tour with a beautiful lesson: we are all the same, independent of our background. I felt strong solidarity and hope that the city will keep its promise and try to honour its forgotten jewish history.`
      ]
    },
    {
      type: 'text',
      value: [
        `I came back to the hostel in a state of Katarsis. Clara had a wonderful idea - we go to an Asian restaurant for lunch. Our last conversation was heartfelt. I am pretty sure we will see each other again. Especially because Clara invited me to her home several times, with all seriousness. Saying goodbye at the hostel to her, Pauline and Gregor, felt strange, but I was mostly feelig grateful.`,
        `Now I am sitting in the bus to Skopje, admiring the mountains in Northern Macedonia, thinking again: this is funny, what am I doing here?`,
      ]
    },
  ],
  signature: '07/09/2023 - 09/09/2023'
}

export default post1