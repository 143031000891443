<template>
  <div class="about-wrapper">
    <div class="about">
      <div class="text-container">
        <div class="text" v-html="text"></div>
      </div>

      <div class="image-container">
        <div class="image">
          <div class="circle-layer-below">
            <div class="gallery-circle circle-right circle-blue">
              <div class="gallery-image-description-text description-right loading">
                <div>loading</div>
                <div class="loader"></div>
              </div>
            </div>
          </div>

          <div class="gallery-image-source-container">
            <img src="@/assets/me.jpeg" class="gallery-image" />
          </div>

          <div class="circle-layer-above">
            <div class="gallery-circle circle-right circle-blue">
              <div class="gallery-image-description-text description-right">{{ imgDescription }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
      imgDescription: 'me in Casa Milà in Barcelona',
      text: `
        Hi there!
        <br><br>
        To those who don't know me, I am Andrea, an adventurous soul from Slovakia. Every now and then, I go on travel expeditions while working my remote job as a web developer. I had so much to tell to my friends, family and other travel enthusiasts, it became impossible to keep everyone up to date. I started writing my travel diary as a way of capturing the memories and things I've learned along the way. And who knows, maybe it might even inspire someone to travel somewhere specific.
        Feel free to read as much as you want, or just scroll through for the pictures.
        <br><br>
        Till the next journey!
      `
    }
  },
}
</script>

<style scoped lang="scss">
@import '../main.scss';

.about-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.about {
  width: 100%;
  aspect-ratio: 10 / 5.7;
  display: flex;
  flex-direction: row;
  gap: 5vw;

  @media only screen and (max-width: 1000px) {
    gap: 3vw;
    padding: 10px;
  }

  @media only screen and (max-width: 500px) {
    aspect-ratio: 1 / 1;
    gap: 3vw;
    padding: 0px;
  }
}

.image-container {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image {
  display: flex;
  margin: auto 0;
  aspect-ratio: 1 / 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
}

.gallery-image-source-container {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 20;
}

.gallery-image {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;

  @media only screen and (max-width: 500px) {
    aspect-ratio: 4 / 7;
  }
}

.circle-layer-below,
.circle-layer-above {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.circle-layer-above {
  z-index: 30;
}

.circle-layer-below {
  z-index: 10;
}

.circle-layer-above .gallery-circle {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease;
  -moz-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}

.image:hover .gallery-circle {
  opacity: 1;
}

.gallery-circle {
  width: 13vw;
  height: 13vw;
  border-radius: 50%;
  position: absolute;

  @media only screen and (max-width: 1000px) {
    width: 15vw;
    height: 15vw;
  }

  @media only screen and (max-width: 500px) {
    width: 30vw;
    height: 30vw;
  }
}

.circle-right {
  bottom: 20%;
  right: -15%;
}

.circle-blue {
  background-color: $blue;
}

.gallery-image-description-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 60%;
  font-family: 'RozhaOne';
  font-size: 1.1vw;
  color: $sand;

  @media only screen and (max-width: 1000px) {
    font-size: 1.7vw;
  }

  @media only screen and (max-width: 500px) {
    font-size: 2.7vw;
  }
}

.description-right {
  margin-left: auto;
  margin-right: 5vw;
  text-align: right;
}

.text-container {
  display: flex;
  align-items: center;
  max-width: 45%;

  @media only screen and (max-width: 1000px) {
    max-width: 50%;
  }
}

.text {
  color: $dark-brown;
  text-align: justify;
  font-family: 'Lora';
  font-size: 1.1vw;

  @media only screen and (max-width: 1000px) {
    font-size: 1.7vw;
  }

  @media only screen and (max-width: 500px) {
    font-size: 2.7vw;
    padding-left: 10px;
  }
}

.loading {
  width: 60%;
  height: 100%;
  gap: 5px;
  flex-direction: row;
  align-items: center;
}

.loader {
  margin-top: 2px;
  height: 10px;
  width: 20px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #FFF8E7 90%,#0000);
  background:
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
 20% { background-position: 0%   0%, 50%  50%,100%  50% }
 40% { background-position: 0% 100%, 50%   0%,100%  50% }
 60% { background-position: 0%  50%, 50% 100%,100%   0% }
 80% { background-position: 0%  50%, 50%  50%,100% 100% }
}

</style>