<template>
  <div class="gallery">
    <template v-for="(pair, pairIndex) in imagePairs(images)" :key="pairIndex">
      <div class="gallery-row">
        <template v-for="(image, index) in pair" :key="index">
          <div class="gallery-image-container">
            <div class="circle-layer-below">
              <div class="gallery-circle" :class="[image.position, image.color]">
                <div class="gallery-image-description-text loading" :class="textAlignments[image.position]">
                  <div>loading</div>
                  <div class="loader"></div>
                </div>
              </div>
            </div>

            <div class="gallery-image-source-container">
              <img :src="image.src" class="gallery-image"/>
            </div>

            <div class="circle-layer-above" @click="emitImageClick(image.src)">
              <div class="gallery-circle" :class="[image.position, image.color]">                    
                <div class="gallery-image-description-text" :class="textAlignments[image.position]">
                  <div>{{ image.description[0] }}</div>
                  <div>{{ image.description[1] }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
  props: {
    images: Array,
  },
  data() {
    return {
      positions: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      textAlignments: {
        'top-left': 'left',
        'top-right': 'right',
        'bottom-left': 'left',
        'bottom-right': 'right',
      }
    }
  },
  methods: {
    imagePairs (images) {
      const pairs = []
      let positionIndex = 0

      for (let i = 0; i < images.length; i += 2) {
        const pair = []
        for (let j = 0; j < 2; j++) {
          if (images[i + j]) {
            const image = { ...images[i + j] }
            image.position = this.positions[positionIndex]
            positionIndex = (positionIndex + 1) % this.positions.length
            pair.push(image)
          }
        }
        pairs.push(pair)
      }
      return pairs
    },
    emitImageClick (src) {
      this.$emit('image-click', src)
    }
  },
}
</script>

<style scoped lang="scss">
@import '../main.scss';

.gallery {
  width: 100%;
  margin: 80px 0;

  @media only screen and (max-width: 1000px) {
    margin: 40px 0;
  }
  @media only screen and (max-width: 500px) {
    margin: 30px 0;
  }
}

.gallery-row {
  margin: 50px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4%;

  @media only screen and (max-width: 1000px) {
    margin: 40px 0;
  }
  @media only screen and (max-width: 500px) {
    margin: 30px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}

.gallery-image-container {
  position: relative;
  width: 36%;

  @media only screen and (max-width: 500px) {
    width: 70%;
  }
}

.gallery-image-source-container {
  z-index: 30;
}

.gallery-image {
  position: relative;
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 3 / 4;
  z-index: 30;
}

.circle-layer-below,
.circle-layer-above {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.circle-layer-above {
  cursor: zoom-in;
  z-index: 40;
}

.circle-layer-above .gallery-circle {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.circle-layer-above > .gallery-circle {
  @media only screen and (max-width: 500px) {
    opacity: 1;
  }
}

.circle-layer-above:hover > .gallery-circle {
  opacity: 1;
}

.gallery-circle {
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  z-index: 30px;
  position: absolute;

  @media only screen and (max-width: 1000px) {
    width: 20vw;
    height: 20vw;
  }
  @media only screen and (max-width: 500px) {
    width: 35vw;
    height: 35vw;
  }
}

.top-left {
  top: 0;
  left: -4vw;

  @media only screen and (max-width: 1000px) {
    left: -6vw;
  }
  @media only screen and (max-width: 500px) {
    left: -9vw;
  }
}

.top-right {
  top: 40%;
  right: -4vw;

  @media only screen and (max-width: 1000px) {
    right: -6vw;
  }
  @media only screen and (max-width: 500px) {
    right: -9vw;
  }
}

.bottom-left {
  top: 20%;
  left: -4vw;

  @media only screen and (max-width: 1000px) {
    left: -6vw;
  }
  @media only screen and (max-width: 500px) {
    left: -9vw;
  }
}

.bottom-right {
  bottom: 10px;
  right: -4vw;

  @media only screen and (max-width: 1000px) {
    right: -6vw;
  }
  @media only screen and (max-width: 500px) {
    right: -9vw;
  }
}

.blue {
  background-color: $blue;
}

.pink {
  background-color: $rose;
}

.gallery-image-description-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 60%;
  font-family: 'RozhaOne';
  font-size: 1.1vw;
  color: $sand;

  @media only screen and (max-width: 1000px) {
    font-size: 1.7vw;
  }
  @media only screen and (max-width: 500px) {
    font-size: 3vw;
  }
}

.loading {
  gap: 5px;
  flex-direction: row;
  align-items: center;
}

.left {
  margin-left: 4.5vw;
  text-align: left;

  @media only screen and (max-width: 1000px) {
    margin-left: 6.5vw;
  }
  @media only screen and (max-width: 500px) {
    margin-left: 10vw;
  }
}

.right {
  margin-left: auto;
  margin-right: 4.5vw;
  text-align: right;

  @media only screen and (max-width: 1000px) {
    margin-right: 6.5vw;
  }
  @media only screen and (max-width: 500px) {
    margin-right: 10vw;
  }
}

.gallery-image-source-container {
  z-index: 30;
  position: relative;
}

.loader {
  margin-top: 2px;
  height: 10px;
  width: 20px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #FFF8E7 90%,#0000);
  background:
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
 20% { background-position: 0%   0%, 50%  50%,100%  50% }
 40% { background-position: 0% 100%, 50%   0%,100%  50% }
 60% { background-position: 0%  50%, 50% 100%,100%   0% }
 80% { background-position: 0%  50%, 50%  50%,100% 100% }
}
</style>
