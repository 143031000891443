import { PostProps } from "@/types"

const post0: PostProps = {
  key: 0,
  location: 'Bangkok',
  title: 'Coming soon!',
  content: [ 
    {
      type: 'text',
      value: [
        `Phasellus malesuada nunc sit amet ipsum rutrum pellentesque. Phasellus sollicitudin libero id lectus ultricies mattis quis sed velit. Vestibulum accumsan dolor ut tortor sollicitudin, eleifend molestie felis vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sollicitudin, lacus in rhoncus elementum, dolor odio vehicula urna, eu malesuada turpis ipsum in lorem. Cras blandit odio vitae odio cursus mollis nec quis elit. Quisque felis lectus, varius vel tristique in, blandit id dui. Aliquam tincidunt nisi augue, vitae euismod nunc placerat sed. Etiam lectus enim, auctor in elit quis, volutpat malesuada tortor. Nullam malesuada efficitur nisi, in pretium velit. Nam vitae aliquet diam.
        `
      ],
    },
   ],
  signature: '31/01/2024'
}

export default post0