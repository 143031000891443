import { PostProps } from "@/types"

const post7: PostProps = {
  key: 7,
  x: '30%',
  y: '50%',
  location: 'Athens',
  title: 'Relaxed in the Classic City',
  content: [
    {
      type: 'text',
      value: [
        `I was surprised my bus had arrived much earlier than I expected, already at 4 in the morning. This means I got a lot less sleep than I expected (or more like bus naps). The neighbourhood where we got off the bus was terribly dark and scary. I only had one night booked, and that was for tomorrow - I thought I would be in Athens at 6, so I did not book for this night. Also, this was the first night I decided to go for a hotel. I thought I would welcome such privacy and rest. I arrived at the hotel, hoping I can relax at the lobby, only to realize the gates were closed. I didn't think much about it, just that I want to find a cheap place to spend these few hours. I found a random wifi on the street (no data anymore) and Googled a hostel nearby. Luckily, they had a bed for me, I dropped off my stuff and immediately crawled into the bed, trying to not wake anybody.`,
        `The sleep was delightful. I took a shower, felt like a person again, and worked for a few hours from the hostel, until the checkout. Then I moved to Starbucks and worked there for the rest of the day.`,
        `When I arrived at the hotel, I realized something. Yesterday, I was standing on the wrong side of the road, thinking I am at the gates of the hotel (this is a sign to recalibrate your positioning in your phone!). This hotel actually has a 24-hour reception, so it was open at night. The information on Booking, and contact options were so weak, and my tiredness at night was so strong, that I didn't bother double-checking all of this. But honestly, I am grateful I went to get a cheap bed at a hostel - even for a few hours. I felt rested and it was worth it.`,
        `After work, I had a walking tour booked - of course! This one was also suggested by Gregor from Thessaloniki. After checking in, I collapsed in bed and tried to enjoy the few minutes I had before going on the tour. As I am laying down, I am slowly feeling the gravity getting stronger. I want to force myself to get up and go, but my body resists. I had to be honest with myself: would I really enjoy walking through the town for 3 hours right now? Would this be a memorable experience or more of a survival?`,
        `I chose to stay in. I ate pizza, watched documentaries and went to bed early. I am actively refusing to feel guilty. I am admitting to myself that travel can be hard. Especially if one changes locations so often. On my next traveling season, I will definitely stay at a location for much longer. This will make me feel more deeply connected to each place and will save a lot of money. Also, because I work full-time on my travels, I really only use half of the time to explore.`
      ]
    },
    {
      type: 'text',
      value: [
        `The next day, I stayed in until checkout. Then I moved to Starbucks, but this time the wifi gave up on me. I finished some work which was doable without wifi and moved into a café nearby. I finished at about 5 and walked towards two goals. First: food, second: Acropolis.`,
        `With a burek-like pastry, I happily cruised through the streets. I found Athens a lot more organized, beautiful, and spacious than Thessaloniki - understandably so - but my standards were lower. I thought, I could be my own tour guide since I still remembered something about Athens' monuments from my art history classes. I had seen the pictures so many times, I found it hard to put them in the context of a real city and not see them as separate objects.`,
        `I walked towards Acropolis, dropping my jaw. I walked around and saw it from all angles until I arrived at a hill next to it, which overlooked Acropolis and the whole city. The view was really spectacular. There were a lot of tourists taking pictures (I was in this category), and some locals who sat down, talked, and watched the sunset. Before I could resemble the behavior of a local, I had to move on to make sure I caught my ferry. On the way back, I also saw the Forum Romanum and the Agora. I felt satisfied. I knew Athens maybe deserved more time for exploration, but I had managed to hit the three highlights. They all looked better in the context of a real city, rather than the separate objects I knew them as.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post7/1.jpg',
          description: ['The Acropolis'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post7/2.jpg',
          description: ['Roman Agora'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post7/3.jpg',
          description: ['colorful buildings in the town'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post7/4.jpg',
          description: ['the ferry to Crete ready for departure'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `I am travelling by ferry to Heraklion, Crete. The next week will be quite different from the previous ones. I will exchange the work-life balance with vacation life-life balance. The bunk beds with big comfortable beds. The hostel community with hanging out with my favorite human - my mom. The ferry was somehow mostly empty, so I had a big couch area all for myself. I managed to get surprisingly good quality sleep. That was the perfect start of a very good quality vacation with my mom.`,
      ]
    },
  ],
  signature: '18/09/2023 - 19/09/2023'
}

export default post7