import { PostProps } from "@/types"

const post20: PostProps = {
  key: 20,
  x: '78%',
  y: '68%',
  location: 'Alanya',
  title: 'Taking slow steps towards Cyprus',
  content: [
    {
      type: 'text',
      value: [
        `I had the last day in Antalya, and a few more days in Turkey before I made my way to Cyprus. These days turned out to be more challenging than I expected. My journey to Cyprus was especially full of plot twists. But more about that in the next post.`
      ]
    },
    {
      type: 'text',
      value: [
        `I decided I would only do one thing on Sunday. My back pain was peaking, and I was actually really tired from probably everything by now. I took the bus to the far end of the city to see the waterfalls. I took it very easy as this was really the only thing I planned to do. I was not really present anymore, but I was still rationally aware that the waterfalls were beautiful. The park in which they were located was giving me autumn vibes. It felt homey and familiar, as the nature there looked a bit less exotic and a bit more like what I am used to at home.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post20/1.jpg',
          description: ['Upper Duden Waterfalls'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post20/2.jpg',
          description: ['the waterfalls from below'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `The next day, I said goodbye to hostel friends and made my way to Alanya. I massaged my back the whole day. I booked a room which was strangely cheap for the fact that it was pretty luxurious. It was actually a hotel room. I had my own kitchen and bathroom. Alanya is more like a resort place for family vacations, so I have not really seen any hostels. My place was only 5 minutes away from the beach. Even though I mostly wanted to eat and sleep, I forced myself to go for a sunset swim and it did me well. I felt really relaxed. I bought some canned food to make an easy dinner. The kitchen was very humble when it came to utensils, so I could not be too creative with the cooking anyways. For example, I had to go ask at the reception to get matches to start a fire at the gas stove. Still, it felt good to eat a warm dinner.`
      ]
    },
    {
      type: 'text',
      value: [
        `On Tuesday, I already had to check out. I sat at the terrace of the hotel and worked there, in amazing conditions. They made me coffee and let me eat my lunch there too. After work, I left my bags at the reception and went to explore a bit of the town.`,
        `I followed the main road, which was full of shops. On top of a hill in front of me was a nice looking fortress. The hill and the sea looked romantic. However, the town was very consumerist and touristy - to the point that they did not even try to sell you the Turkish culture anymore - but rather an insane amount of all kinds of things that you usually see on Aliexpress. But above all, fake brands. I noticed I was getting frustrated and angry at everything. The shops, loud people, loud cars, crowds… This showed me the importance of trying to keep balance on the travels too. That means, seeking excitement, city, people, but every now and then, spending time alone, taking a walk in nature, meditating or reading. I think I did both, but I resorted to the latter only when I overdid the first. But I am learning all of this on my own skin and it feels good.`
      ]
    },
    {
      type: 'text',
      value: [
        `To compensate for the negative thoughts in my head, I tried to search for things that I like. I was searching for nice people and their smiles, their interesting clothing, some interesting food or drinks I saw in some stores. I tried to change the noise from the cars into music in my head.`,
        `When I was climbing the hill towards the fortress, it started getting dark. The sun went down already around 18, since the summer was approaching its end, plus there were mountains here that hid the sun a bit before the sunset. I explored the fortress, managed to not get bitten by stray dogs and then went down into the city and had some dinner in a Turkish diner.`,
        `I was peaceful again, reading my book and enjoying my dinner. I was texting my mom and thinking about how much I love her and miss her. After collecting my bags, I made my way to the bus station and took the night bus.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post20/3.jpg',
          description: ['the beach in Alanya'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post20/4.jpg',
          description: ['the fortress and the town at night'],
          color: 'blue',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `My last Turkish day was in Tasucu. This was actually the port town, from which I was supposed to take the ferry in the afternoon. I expected my bus to arrive at 6, but it arrived at 4. I was lucky to find a bakery that was already open. I bought some water, rested my head on the table and took small naps here and there. The owner brought me some pastries for free - they were so good I had to buy some to eat more. Around 7, I moved to a cafe, but it was not a lucky choice - they had no toilet, only one seat and had to unplug some device so that I could charge my laptop there. I was promised a mosque two streets away has a toilet. I took a break to see whether it was open - it was not. On my second unsuccessful journey to the toilet, I walked along another cafe and was informed it would open within an hour.`
      ]
    },
    {
      type: 'text',
      value: [
        `And this cafe ended up being my safe haven. I could comfortably use the toilet. There was good wifi and plugs everywhere. I got some coffee and juice. I was communicating with a very young waiter (I almost doubted he was of legal age to work). He did not speak English, so we used google translate - but somehow it was still a pleasant encounter and we managed to joke around too. At some point the owner arrived and made me an amazing omelette for lunch. I was the only guest in the cafe and I stayed the whole working day. It started to feel like I was a guest in their home, as they only had one visitor to take care of - so they made sure they took care of me really well.`
      ]
    },
    {
      type: 'text',
      value: [
        `The owner came up to me to chat - he was expecting I was Russian so he chose to speak in Russian. It has happened to me several times by now. I corrected him and asked him in a way that did not sound rude - why are there so many Russians around? Apparently, there is a big power plant being built in the region and a lot of Russian workers and engineers got jobs in that project. That finally explained it to me and I was glad I let my curiosity win over tactfulness again.`
      ]
    },
    {
      type: 'text',
      value: [
        `In the afternoon, I was done with work and ready to move to the port to catch my ferry. I had a pretty bad back pain, so my backpack felt two times heavier than usual. The walking distance was 30 minutes, but I took breaks every 5 minutes. There was a couple of elderly women walking on the promenade by the beach. Each time I overtook them, then I took a break, they overtook me and so on…`,
        `I arrived at the port about half an hour before the ferry departure. Maybe late for a smart responsible person, but for me personally it felt appropriate - especially after my smooth ferry experiences in Athens and on Crete.`,
        `And this is the place where a plot twist happened and everything spiralled downwards. I experienced the most stressful time of my travels - and I will talk about it in the next blog post.`
      ]
    }
  ],
  signature: '15/10/2023 - 17/10/2023'
}

export default post20