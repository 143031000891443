import { createStore } from 'vuex'
import { JourneyProps, PostProps } from '../types'
import postsBalkans from '../posts/00_balkans/posts'
import router from '@/router'

interface RootState {
  journeys: JourneyProps[]
  selectedJourneyIndex: number
  posts: { name: string; path: string; data: PostProps; }[]
  selectedPostIndex: number
  viewMode: string
  notFound: boolean
}

const store = createStore<RootState>({
  state: {
    journeys: [
      { name: 'Balkans', path: 'balkans', folder: '00_balkans' },
      { name: 'South-East Asia', path: 'southeast_asia', folder: '01_southeast_asia' },
    ],
    selectedJourneyIndex: 0,
    posts: postsBalkans,
    selectedPostIndex: 0,
    viewMode: 'map',
    notFound: false,
  },
  mutations: {
    SET_SELECTED_JOURNEY_INDEX (state, index: number) {
      state.selectedJourneyIndex = index
    },
    SET_SELECTED_POST_INDEX (state, index: number) {
      state.selectedPostIndex = index
    },
    // TODO: toggling journeys
    NEXT_JOURNEY (state) {
      state.selectedJourneyIndex = Math.min(state.selectedJourneyIndex + 1, state.journeys.length - 1)
    },
    PREV_JOURNEY (state) {
      state.selectedJourneyIndex = Math.max(state.selectedJourneyIndex - 1, 0)
    },
    NEXT_POST (state) {
      state.selectedPostIndex = Math.min(state.selectedPostIndex + 1, state.posts.length - 1)
    },
    PREV_POST (state) {
      state.selectedPostIndex = Math.max(state.selectedPostIndex - 1, 0)
    },
    SET_VIEW_MODE (state, viewMode) {
      state.viewMode = viewMode
    },
    SET_NOT_FOUND (state, notFound) {
      state.notFound = notFound
    }
  },
  actions: {
    nextPost ({ commit, state }) {
      if (state.selectedPostIndex < state.posts.length - 1) {
        commit('NEXT_POST')
        const postPath = this.state.posts[state.selectedPostIndex].path
        router.push({ path: `/balkans/${postPath}` })
      }
    },
    prevPost ({ commit, state }) {
      if (state.selectedPostIndex > 0) {
        commit('PREV_POST')
        const postPath = this.state.posts[state.selectedPostIndex].path
        router.push({ path: `/balkans/${postPath}` })
      }
    },
    goToPost ({ commit, state }, index) {
      commit('SET_SELECTED_POST_INDEX', index)
      const postPath = this.state.posts[state.selectedPostIndex].path
      router.push({ path: `/balkans/${postPath}` })
    },
  },
})

export default store
