<template>
  <div class="App">
    <div class="content">
      
      <!-- left arrow -->
      <div class="arrow-space" :class="{'arrow-space-visible' : showButton}">
        <button v-if="selectedPostIndex > 0" class="arrow-button" @click="prevPost">
          <img src="@/assets/arrow-left.svg" alt="Left Arrow" />
        </button>
      </div>

      <!-- Router (post) -->
      <router-view class="post" @mouseover="mouseOverPost=true" @mouseout="mouseOverPost=false" />

      <!-- right arrow -->
      <div class="arrow-space" :class="{'arrow-space-visible' : showButton}">
        <button v-if="selectedPostIndex < posts.length - 1" class="arrow-button arrow-button-right" @click="nextPost">
          <img src="@/assets/arrow-right.svg" alt="Right Arrow" />
        </button>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      mouseOverPost: false,
    }
  },
  computed: {
    journeys () {
      return this.$store.state.journeys
    },
    posts () {
      return this.$store.state.posts
    },
    selectedPostIndex () {
      return this.$store.state.selectedPostIndex
    },
    showButton () {
      return !this.mouseOverPost && !this.$store.state.notFound
    }
  },
  methods: {
    nextPost () {
      this.$store.dispatch('nextPost')
    },
    prevPost () {
      this.$store.dispatch('prevPost')
    },
  },
}
</script>

<style lang="scss">
@import 'main.scss';

body {
  background-color: $dark-brown;
}

.App {
  padding: 10px 5px;

  /* allow more space on left and right for big screens */
  @media only screen and (min-width: 1200px) {
    padding: 10px 15%;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: row; /* arrow - post - arrow */
  gap: 2%; /* space between post and arrows */

  @media only screen and (max-width: 1200px) {
    gap: 10px;
  }
}

.arrow-space {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0; /* initially hide the arrows */
  pointer-events: none; /* prevent interaction with hidden arrows */
  transition: opacity 0.3s ease;
}

.arrow-space-visible {
  opacity: 1;
  pointer-events: auto; /* allow interaction with arrows when visible */
}

.arrow-button {
  position: fixed; /* Fixed positioning relative to the viewport */
  top: 50%; /* 50% from the top of the viewport */
  transform: translateY(-50%); /* Adjust position to center the element */
  height: 48px;
  width: 100%; /* Adjust as necessary */
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;

  img {
    height: 100%;
    max-width: 100%;
  }
}

</style>
