import { PostProps } from "@/types"

const post17: PostProps = {
  key: 17,
  x: '57.5%',
  y: '12%',
  location: 'Istanbul',
  title: 'Using the last day to the fullest',
  content: [
    {
      type: 'text',
      value: [
        `With Nathalie, we made a plan to go to have coffee together this morning. She asked me how I am doing, I told her the essential oils must work since I was doing better. We agreed to meet at 10, before that I had to shower, pack, and check out.`,
        `We walked towards the Balat area and searched for colourful rooftop cafes. We found a perfect one. We ordered smoothies, took a lot of pictures, and got into conversations about goals and career. Nathalie is a trainer and consultant for these things, so it was quite interesting to hear her point of view on everything.`,
        `At some point, Nathalie noticed there was a group at a table with a lot of food, and one guy from that group stayed away from the food and was watching videos on the internet. She pointed it out and he showed us he is watching videos about fasting - as he just started his fast today. We exchanged a few jokes and the guy asked us to sit with them at the table. There was also his wife and his nephew. They were all Turkish, but all quite well-traveled.`,
        `We had some interesting conversations. Everything was very open, everyone shared their opinion and experiences on things. We talked about Turkey, Islam, Christianity, culture, and some more.`,
        `The most meaningful lesson for me was that when you travel somewhere, you should behave like a local - in order not to disturb their environment. For example, when you travel to a destination where kids sell stuff, you should not buy it, because this motivates kids against going to school. In another case, when they beg for things, you should not just give it to them but try to exchange for something - this way you teach them trade. If someone wants you to pay in euros or dollars, you should still attempt to pay them in their local currency, to help strengthen it. Otherwise, no one wants to use it anymore. And the best one - try to find the restaurants and accommodation where locals stay, to avoid supporting increasing prices due to tourism. Most of the time, the places where locals go are the most authentic anyways, other places might just try to offer what the tourists want.`,
        `We said goodbye around 1. Nathalie and I still had lunch together and walked to her bus. She got on and got lost into the crowd, with just her head sticking out. 'That's the experience!' She shouted out and we both laughed and the bus moved on, together with Nathalie's head.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post17/1.jpg',
          description: ['Bob Marley at the cafe'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post17/2.jpg',
          description: ['me with the colorful view'],
          color: 'blue',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Now my task was to go to Topikapi palace, but I visited much more than that. On the way there, I got sucked into the Yeni Cami mosque. It was mostly pinkish/greenish, which was wonderful. I took a lot of pictures.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post17/3.jpg',
          description: ['the beautiful pink Yeni Cami'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post17/4.jpg',
          description: ['the mosque lamps'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Later I walked through the Gülhane Park and decided to relax there for a bit. For the first time now, I noticed small signs of autumn incoming. The leaves were slowly turning yellow. The park was full of families relaxing there on a sunny Saturday. I realised I love the atmosphere in parks.`,
        `When I made it to Topikapi, I noticed my legs were quite tired - which makes sense, I was just recovering from sickness. I tried to observe the palace as much as I could from the garden. I sat down for a moment and went through a decision process: I was already pretty satisfied visually - I've seen all the ornaments, colours, and arches. I realised the 25€ are not gonna give much added value to my already amazing stay - especially not if I'm already this tired.`,
        `When I collected some strength, I decided to walk to the port. I was ready for my Bosporus ride - this usually costs around 20€ as well, but that's for tourists. If you resist all the sellers on the port who are trying to lure you on their boat for this price, you can simply walk through the port turnstile using your Istanbulkaart and use the boat as a means of public transport for 0.50€. Another amazing trick I learned from my coffee group today.`,
        `My plan was to go towards the Galata tower and visit a Starbucks closeby. This would be just across the river. However, I either missed my stop, or perhaps this was not a stop to get off the boat since no one did that, only more people got on. And we travelled with the boat all the way to the Asian side. I accepted my not-so-bad fate and instead of sitting in Starbucks, I was enjoying a sunset on the boat. Luckily, I was still on time for my bus.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post17/5.jpg',
          description: ['a view from the Gülhane Park'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post17/6.jpg',
          description: ['balconies that caught my attention'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post17/7.jpg',
          description: ['a typical landscape in Istanbul - full of domes and minarets'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post17/8.jpg',
          description: ['the sunset from the Bosphorus'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `For that, I left a big buffer, which turned out to be a smart decision. After I picked up my bags from the hostel, I had to stand at the bus stop for about 30 minutes, trying to find a bus that could squeeze me in. But the buses were so packed it was scary, and they came and went at a crazy speed, so there was not much time for decision-making. It was hard to know which numbers went to the station, google maps could not help much either.`,
        `After this followed a much healthier metro ride. I made friends with a young mother who did not speak much English but was really kind. At the bus station, I had just about an hour to find toilets and eat dinner. I was at a Turkish fast-food restaurant with a very friendly waiter, with bad English - but we still talked about simple things. After dinner, he gave me some Turkish tea on the house - a very sweet custom of Turkish hospitality I almost got used to by now.`
      ]
    }
  ],
  signature: '07/10/2023'
}

export default post17