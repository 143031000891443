<template>
  <div v-show="post" class="post">
    
    <transition name="overlay-fade">
      <div v-if="showOverlay" class="overlay" @click="closeOverlay">
        <div class="overlay-content">
          <img :src="selectedImageSrc" class="overlay-image">
        </div>
      </div>
    </transition>

    <ModalNavigation :isVisible="showModalNavigation" @close="showModalNavigation=false" />

    <!-- MENU -->
    <div class="title">
      <div class="title-text">andrea goes...</div>
      <div class="navigation-button" @click="openModalNavigation('about')">
        <img class="navigation-icon" src="@/assets/menu.svg" />
      </div>
    </div>

    <div class="subtitle">
      <!-- <hr class="subtitle-divider"> -->
      <div class="subtitle-text">blog</div>
      <hr class="subtitle-divider">
    </div>

    <!-- HEADER FOR BIG SCREENS -->
    <div v-if="showBigScreenHeader" class="post-header">
      <div class="circle-container" @click="openModalNavigation('map')">
        <div class="circle invisible">
          <div class="circle filled">
            <div class="circle-number">{{ post.key + 1 }}</div>
          </div>
        </div>
      </div>
      <div class="post-location">{{ post.location }}:</div>
      <div class="post-title">
        <div>{{ firstHalf }}</div>
        <div>{{ secondHalf }}</div>
      </div>
    </div>

    <!-- HEADER FOR SMALL SCREENS -->
    <div v-else class="post-header-small">
      <div class="post-header-small-row-1">
        <div class="circle-container" @click="openModalNavigation('map')">
        <div class="circle invisible">
          <div class="circle filled">
            <div class="circle-number">{{ post.key + 1 }}</div>
          </div>
        </div>
      </div>
        <div class="post-location">{{ post.location }}</div>
      </div>
      <div class="post-title">{{ post.title }}</div>
    </div>


    <!-- CONTENT -->
    <div v-for="(block, index) in post.content" :key="index">

      <div v-show="shouldDisplayParagraphDivider(index)">
        <div class="paragraph-divider">
          <div class="paragraph-divider-dot"></div>
        </div>
      </div>
      
      <div v-if="block.type === 'text'">
        <p v-for="(text, textIndex) in block.value" :key="textIndex" class="post-paragraph">{{ text }}</p>
      </div>
      
      <div v-else-if="block.type === 'images'" class="gallery">
        <Gallery :images="block.value" @image-click="showImageOverlay" />
      </div>
    </div>

    <!-- SIGNATURE -->
    <p class="post-signature">{{ post.signature }}</p>

    <hr class="final-divider" />
  </div>
</template>

<script>
import ModalNavigation from './ModalNavigation.vue'
import Gallery from './Gallery.vue'

export default {
  name: 'Post',
  components: {
    ModalNavigation,
    Gallery
  },
  props: {
    post: Object
  },
  data() {
    return {
      showModalNavigation: false,
      showBigScreenHeader: true,
      showOverlay: false,
      selectedImageSrc: '',
      positions: [
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right'
      ],
      textAlignments: {
        'top-left': 'left',
        'top-right': 'right',
        'bottom-left': 'left',
        'bottom-right': 'right'
      }
    }
  },
  computed: {
    firstHalf () {
      return this.splitTitle(this.post.title).firstHalf
    },
    secondHalf () {
      return this.splitTitle(this.post.title).secondHalf
    },
  },
  methods: {
    openModalNavigation (viewMode) {
      this.$store.commit('SET_VIEW_MODE', viewMode)
      this.showModalNavigation = true
    },
    showImageOverlay (src) {
      this.selectedImageSrc = src
      this.showOverlay = true
    },
    closeOverlay () {
      this.showOverlay = false
    },
    splitTitle (title) {
      const words = title.split(' ')
      const halfIndex = Math.ceil(words.length / 2)
      const firstHalf = words.slice(0, halfIndex).join(' ')
      const secondHalf = words.slice(halfIndex).join(' ')
      return { firstHalf, secondHalf }
    },
    handleResize () {
      this.showBigScreenHeader = window.innerWidth > 800
    },
    shouldDisplayParagraphDivider (index) {
      if (index === 0) return false
      const currentBlock = this.post.content[index]
      const previousBlock = this.post.content[index - 1]
      return currentBlock.type === 'text' && previousBlock.type === 'text'
    },
    imagePairs (images) {
      const pairs = []
      let positionIndex = 0

      for (let i = 0; i < images.length; i += 2) {
        const pair = []
        for (let j = 0; j < 2; j++) {
          if (images[i + j]) {
            const image = { ...images[i + j] }
            // Assign position
            image.position = this.positions[positionIndex]
            positionIndex = (positionIndex + 1) % this.positions.length
            pair.push(image)
          }
        }
        pairs.push(pair)
      }
      return pairs
    },
  },
  created () {
    this.$store.commit('SET_SELECTED_POST_INDEX', this.post.key)
    this.handleResize()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style scoped lang="scss">
@import '../main.scss';

.post {
  padding-top: 7%;
  padding-bottom: 7%;
  border-radius: 14px;
  background-color: $sand;
  box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.5);
}

.title,
.subtitle,
.post-header,
.post-header-small,
.post-paragraph,
.post-signature,
.final-divider {
  padding-left: 7%;
  padding-right: 7%;
}

.final-divider {
  margin-left: 7%;
  margin-right: 7%;
}

/*  title  */
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'RozhaOne';
  color: $rose;
  margin-bottom: 50px;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 500px) {
    margin-bottom: 30px;
  }
}

.title-text {
  font-size: 50px;

  @media only screen and (max-width: 1000px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 20px;
  }
}

.navigation-button {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.navigation-icon {
  opacity: 1;
  width: 40px;
  transition: opacity 0.3s ease;

  @media only screen and (max-width: 500px) {
    height: 20px;
  }
}

.navigation-icon:hover {
  opacity: 0.7;
}

/*  subtitle  */
.subtitle {
  display: flex;
  align-items: center;
  font-family: 'RozhaOne';
  color: $rose;
  margin-bottom: 50px;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 500px) {
    margin-bottom: 30px;
  }
}

.subtitle-text {
  font-size: 28px;
  margin-right: 20px;

  @media only screen and (max-width: 1000px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 15px;
    margin-right: 10px;
  }
}

.subtitle-divider,
.final-divider {
  height: 100%;
  flex-grow: 1;
  border: 1px solid $rose;
}

.post-header {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-family: 'HedvigLetters';
  margin-bottom: 30px;
}

.circle-container {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  margin-right: 20px;
}

.circle {
  cursor: pointer;
  position: absolute;
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  transition: box-shadow 0.5s ease;
}

.invisible {
  box-shadow: 0 0 0px $blue;
  transition: box-shadow 0.3s ease;
  background-color: transparent;
}

.filled {
  background-color: $cappuccino;
}

.circle-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $sand;
  font-family: 'HedvigLetters';
  font-size: 20px;
  font-weight: bold;
  transition: color 0.5s ease;
}

.circle-container:hover .invisible {
  box-shadow: 0 0 12px $blue;
}

.circle-container:hover .circle-number {
  color: $blue;
}

.post-title,
.post-signature {
  font-weight: 900;
  font-size: 16px;
  color: $blue;
}

.post-signature {
  margin: 50px 0;
  font-size: 20px;
}

.post-location {
  font-size: 40px;
  font-weight: bold;
  margin-right: 15px;
  color: $blue;
}

// small header:
.post-header-small {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-family: 'HedvigLetters';
  margin-bottom: 30px;
}

.post-header-small-row-1 {
  display: flex;
}

.post-header-small {
  .circle, .circle-container {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }

  .circle-number {
    font-size: 12px;
  }
}

.post-header-small .post-location {
  font-size: 22px;
  overflow-wrap: anywhere;
  margin-bottom: 5px;
}

.post-header-small .post-title,
.post-header-small ~ .post-signature  {
  font-size: 14px;
}

// divider
.paragraph-divider {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.paragraph-divider-dot {
  width: 8px;
  height: 8px;
  background-color: $cappuccino;
  border-radius: 50%;
}

.post-paragraph {
  color: $dark-brown;
  text-align: justify;
  font-family: 'Lora';
  font-size: 20px;

  @media only screen and (max-width: 1000px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 1;
  cursor: zoom-out;
}

.overlay-content {
  height: 95%;
  transform: scale(1);

  @media only screen and (max-width: 500px) {
    height: unset;
    width: 100%;
  }
}

.overlay-image {
  object-fit: cover;
  aspect-ratio: 3 / 4;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.overlay-fade-enter-active,
.overlay-fade-leave-active {
  transition: opacity 0.5s ease;
}

.overlay-fade-enter-active .overlay-image,
.overlay-fade-leave-active .overlay-image {
  transition: transform 0.5s ease;
}

.overlay-fade-enter-from,
.overlay-fade-leave-to {
  opacity: 0;
}

.overlay-fade-enter-from .overlay-image,
.overlay-fade-leave-to .overlay-image {
  transform: scale(0.9);
}

.overlay-fade-enter-to,
.overlay-fade-leave-from {
  opacity: 1;
}

.overlay-fade-enter-to .overlay-image,
.overlay-fade-leave-from .overlay-image {
  transform: scale(1);
}

</style>
