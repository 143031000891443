import { PostProps } from "@/types"

const post6: PostProps = {
  key: 6,
  x: '5%',
  y: '5%',
  location: 'Durrës',
  title: 'Few hours in Albania',
  content: [
    {
      type: 'text',
      value: [
        `I arrived in Durrës at around 8 o'clock. I felt surprisingly good. I knew exactly where to go - Gyoko recommended a hostel - he knows the owner and the atmosphere. He said I could ask them to stay there on their terrace. I liked the idea of crashing at a hostel more than going to a café.`,
        `The hostel manager and volunteers were extremely kind. An elderly lady who was preparing breakfast even gave me the breakfast. The hostel had a really colorful beautiful common room and a terrace. I was already swearing to myself I have to come and stay at this hostel sometime. I felt very welcome already.`,
        `I ate my breakfast, charged my laptop, did some errands and worked on the blog. Everything seemed fine until the elderly lady suddenly snapped. She started yelling all around the canteen. The volunteers seemed to not respond really, like this is a regular routine. She kept walking around nervously until she stopped right above me and I slowly understood I was the source of her unhappiness. I started feeling embarrassed. I might have crossed her boundary when I decided to charge my laptop there. I closed my laptop and to headed to town. I didn't want to cause more stress to the lady, even though the manager was saying I can ignore her. Also, I still have a few hours before my bus to Athens, and this town deserves more attention from me. I apologized, thanked the manager and said goodbye.`,
      ]   
    },
    {
      type: 'text',
      value: [
        `Durrës is quite the touristy summer paradise place. I was, however, struggling to find some benches in the shade, or some trees to hide under. There was a lot of concrete and marble, but not much greens, which made the city feel incredibly hot. Still, I enjoyed walking along the sea.`,
        `At some point, I noticed a lighthouse and other cute buildings on a hill behind the city center. This seemed like a fun challenge! I turned towards the hill in hope to climb it. However, I arrived at a dead end, so I needed to choose a different path. I repeated this pattern for about 10 times, until I finally found a hidden crooked little pathway which led me there. I was excited to see the non-touristy, more residential area of the town. The view was also pretty nice. When I reached the top, I started feeling really hot, so I decided to search for a café. I found one that I liked a lot, but after sitting there for 10 minutes, I realized I am there totally alone and the waiter is probably gone somewhere on a lunch break, or possibly for the whole day. Eventually, I sat in a tiny park with a little bit of shade under a tree. I tried to romanticize it and ignore the litter around me. A German guy recognized I am a tourist and started talking to me. He has been alone in Durrës for a few days, living in a hotel and he seemed a bit bored and alone now. We talked for a while and when he suggested we go for late lunch, I realized I have to start moving towards the bus station.`,
      ]   
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post6/1.jpg',
          description: ['a building that inspired me'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post6/2.jpg',
          description: ['a view of Durrës from the lighthouse'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `At the bus station, I immediately got offered taxi rides. The drivers were not just annoying, but they genuinely seemed to really want to talk and help. When I refused the taxi, they asked me where I am going and tried to point me towards my stop. At this stop, I saw no signs that there should be a line going to Athens. Another taxi driver came up to me and I asked him whether this was the correct stop.`,
        `In the name of helping me, the taxi driver took me on a round of walk with my giant backpack around the whole bus station to come to the office where they indeed confirmed, I was on the correct stop. We returned to the stop, but that did not seem convincing to the taxi driver. His English was non-existent, but he asked to see my ticket, found a number there and called it. Someone answered and he immediatelly handed me the phone. I was surprised, but I improvised and asked whether this is the line going to Athens.`,
        `The lady on the phone confirmed and asked, whether there is a problem, whether the bus is late. I did not know how else to explain why the taxi driver called her, so I said: "hmm yes, it is a bit late and I am wondering if the bus is still going." I looked at time and the bus was one minute late now. How petty, when a client calls the bus company because the bus is one minute late! The lady told me she will check it for me and call me back. I gave the phone back to the taxi driver, saying she would call back, but he did not understand. He was very happy that he helped and walked away. I could see from the distance that he was getting the call, but declined it. That lady must have been confused. Within 2 minutes, the bus was there. I was thinking how cute it is when people want to help, and sometimes it makes things unnecessarily complicated, but it is still very cute!` 
      ]   
    },
  ],
  signature: '17/09/2023'
}

export default post6