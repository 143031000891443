import { PostProps } from "@/types"

const post5: PostProps = {
  key: 5,
  x: '13.5%',
  y: '12%',
  location: 'Ohrid',
  title: 'Boats and mountains',
  content: [
    {
      type: 'text',
      value: [
        `I woke up late-ish, around 10. I promised to Gyoko I would go on his boat trip today - he organizes one every day, if enough people are willing to join. I had 3 hours before the boat and took my sweet time to take a shower, eat, walk around the sunny town, shop, and talk to people I met in the kitchen and the living room.`, 
        `Here, I had to say goodbye to Magdalena, who had to leave as she had to hitchhike to Skopje to catch her flight and needed a safe buffer of time. I knew this girl for two days, and I felt really sad we are already saying goodbye - our experiences were very bonding.`,
        `Gyoko told me he found many missed calls in the morning and when he called back, it was Jovan, asking if 'the girls' made it safe to the hostel. It warmed my heart, what a good guy.`,
        `The hostel had new arrivals, Stina from Denmark and Ben from Australia. They were more close to my age, so connecting with them was quite effortless. They also joined the boat ride, along with Nick from South Africa, Viljams, and Krzysztof (clearly from Poland:). This was a wonderful group.`,
        `We left the port and directed towards the Bay of Bones. It is an archeological site, where many animal bones were found, dating back to 1200BC - proving there used to be settlements there at that time. Then, we stopped in a village where Gyoko bought some wine for all of us, since we did not have enough. Nick and I used this short stop to quickly get some coffee at a local pub. Another stop was a cute rocky shore, where Viljams offered a good entertainment. He started climbing a rocky wall - he is a climbing instructor and climbing seems to be his passion. Then he started swimming towards the other side of the lake, ignoring the fact that it is about 10 kilometers to get to the other side. Every now and then, we checked whether we can still see a teeny tiny head disappearing and reappearing on the lake horizon. Eventually, we decided to move on with the boat trip and collect him on the way.`,
        `The boat atmosphere was awesome. We joked around, jumped from the boat and swam around in the frisky cold water. I had a surprisingly candid philosophical conversation with Nick. I also talked to Gyoko - I was expecting that this hostel lifestyle, especially when he is so engaged with his guests, must be a lot sometimes. Especially these energetic boat trips. He said he loves the boat trips and would go every day if he could. In general, he talked very nicely about the hostel life. It was awesome to see that someone does it not purely for the profit, but because they love it.`
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post5/1.jpg',
          description: ['the beautiful color of the lake from the boat'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post5/2.jpg',
          description: ['our tipsy group'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post5/3.jpg',
          description: ['Viljams climbing the wall'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post5/4.jpg',
          description: ['the bay of bones'],
          color: 'blue',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `When we got back to the hostel, I took a nice warm shower to warm myself up after the cold lake. Nick, Stina, Ben and I then went to a sushi place. Quite unexpected cuisine to choose in Macedonia but Nick and I wanted something healthy for once. The company was wonderful. We had many interesting talks - we all come from quite different countries and mostly discussed their differences and similarities. The food matched the quality of our conversation.`
      ],
    },
    {
      type: 'text',
      value: [
        `The next day, I went on a hike. This was a solo activity, but I did not mind. I was really excited.`,
        `Following Gyoko's advice, I picked up some burek from the bakery for breakfast. I took the mini-bus to the monastery, but asked the driver to drop me off at the beginning of the Galicica National Park. The driver was cheerful, he asked us if we want music and delivered some nice balkan songs. At the park gate, I managed to hitchhike to get to the starting point of the hike. They were two calm and friendly Swedish guys going to a different hike but from the same starting point. They were friends, but I have wrongly guessed their relationship as a father and a son. I was 100% convinced of this. Well, after this embarrassment I pledged to never assume people's age or relationship ever again.`,
        `Apart from them and one other group of young hikers, I haven't met anyone during the whole hike. I found it weird, because it was a sunny Saturday and this hike is beautiful and famous. In any case, I was lucky to have caught a car.`,
        `The first part of the hike lead through the top of the range. At this point, I could see the Ohrid lake on one side and the Prespa lake on the other. I was surprised the Prespa lake did not seem to have much civilization around it. (Later I learned that there is quite some cheap land - go and get yours quickly!) It was warm and sunny and I was reaching the range, but once I was on top, there was a cloud over me. This was ideal, because I got some rest from the sun, but still saw the sunny lakes and valleys. I listened to some podcasts, and one had a significant impact on me - I recommend Jay Shetty ON Purpose | Dr. Daniel Amen: ON Training Your Brain for Optimal Performance. (Don't let the title fool you, it is about mental health and brain health).`,
        `At some point, the hike turned around and I started descending and entering a forest. Even though I was still following the red signs for the path, I thought the turn happened too early and was worried a bit that I accidentally cut the hike too short. I tried to accept this possible scenario and be grateful I managed to get on the hike in the first place. But luckily, it became clear about one hour later that I am on the right path.`,
        `There was one moment where I did get lost. I was supposed to go through a village called Valestovo - and I was looking forward to this as it was supposed to have a good view and nice restaurants. However, at the first sign of civilization, a cute little church in the forest, I missed a turn and started descending more dramatically. Luckily, I realized this was suspicious. After about 5 minutes, I decided to climb back to the church. There, I sat down at a bench and took this as an opportunity for a little break. The church had a fountain with drinkable water, so I refilled my water bottles.`,
        `I walked around the church, took different paths in different directions and seeing the church from behind, I finally noticed the directional sign towards Valestovo. I saw I need to go uphill a little bit, but I was happy I was not gonna miss it now.`,
        `Here, I walked along some sheep as I entered the village. It was silent and calm, people were relaxing outside on this beautiful day. I had Tavce Gravce in a restaurant with a nice view.`,
        `From the restaurant to the hostel, it was about an hour and a half of walking. I loved seeing Ohrid yet from another angle. When I arrived at the hostel, it was 8 in the evening. The whole trip took me 12 hours.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post5/5.jpg',
          description: ['saying hello to the cows on my way up the mountain'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post5/6.jpg',
          description: ['the lake from above'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post5/7.jpg',
          description: ['a church where I rested and filled my water botthe', '...and got lost'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post5/8.jpg',
          description: ['approaching the town again'],
          color: 'blue',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `The next day, I was gonna leave Ohrid. Such a beautiful hike was the best goodbye. I thanked Gyoko and said goodbye to him and the hostel friends. I wish I would go sleep at a reasonable time, but because of interesting conversations, I managed around midnight. That would not be so bad, if I would not have to wake up at 4 to catch my bus to Durrës.`
      ],
    },
  ],
  signature: '15/09/2023 - 16/09/2023'
}

export default post5