<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'JourneyBalkans',
}
</script>

<style scoped lang="scss">
@import '../main.scss';

</style>

