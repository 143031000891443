import { PostProps } from "@/types"

const post8: PostProps = {
  key: 8,
  x: '36%',
  y: '82%',
  zIndex: 110,
  location: 'Crete',
  title: 'First insights and a relaxed birthday',
  content: [
    {
      type: 'text',
      value: [
        `I arrived in Heraklion and immediately understood how convenient this town was. There were lines on the path guiding me to the city centre. Since it was about 6 in the morning, I could not go to a cafe in town yet, but after 5 minutes of walking I found a trailer café on the side of the road with Wi-Fi and power plugs. Perfect.`,
        `I spent the rest of the day in town, but worked on my laptop and tried to shut my eyes and ignore the town. I will be exploring it together with my mom, after a few days in Rethymno.`,
        `Around 15, I went to the airport, rented a rental car and picked up my mom at the airport. We started driving and here we experienced the first shock from the country. The landscape was orangish, hilly and rocky and the flora was the most exotic one I have seen so far. Every now and then, we got a ridiculously beautiful view of the sea. It felt like I was driving in some game, it looked so beautiful it seemed fake.`,
        `A funny thing we noticed is that people often drive on the opposite side of the road. I thought they were just not respecting the lines, but they were simply leaving some space for others to overtake them. So basically, even when the ‘highway’ only has one lane, people kind of make it as if it had two, and you could comfortably overtake others. Soon enough, I began driving like this myself. When we were slowed down by a slower car in front of us, which was not driving on the roadside, we went like: ‘tsss, amateurs!’`,
        `In Rethymno, we had a studio apartment in the basement, so our balcony was basically a terrace carved down half a metre into the ground. From here, we could easily reach the sea which was just across the road.`,
        `We went shopping, then postponed cooking to go for a quick swim. It was already dark now, we were the only ones there. I was surprised why no one goes swimming in the dark, it was quite nice.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post8/1.jpg',
          description: ['our friend Stavros saying hi at our apartment'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post8/2.jpg',
          description: ['cute houses in Rethymno`s city centre'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `The next morning, I woke up as a 26-year-old. I woke up without the alarm clock - vacation is a wonderful thing!`,
        `We went for a ‘morning swim’ through the balcony, we didn’t even wear shoes. We did not know we would like it so much we would stay until noon. If I knew, I would wear sunscreen and avoid burning my bum. But it was amazing, we swam, floated, lay on the edge of the sea and let the waves come and go, we walked on the painful rocks, we went exploring back and forth. Several times, we got out of the sea and then returned.`,
        `In the afternoon, we took a stroll to the town. Our first stop was at a really cute historical lighthouse. In the town, we were amazed by the cute colourful houses and flowers, and cats relaxing everywhere. I tried taking pictures, but it never looks as good as it does in reality. We visited some shops with scarves, jewellery and food. We tried to look at some historical mosques, but since these are Ottoman memorials, they were not maintained after by the Greeks.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post8/3.jpg',
          description: ['the historical lighthouse of Rethymno'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post8/4.jpg',
          description: ['stray cat relaxing in the flowers'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post8/5.jpg',
          description: ['a fountain in the city centre'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post8/6.jpg',
          description: ['a port where we had a birthday lunch'],
          color: 'pink',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `On the way back, my mom invited me to dinner in a restaurant on the port. I realised this was an absolute dream way to spend my birthday. I felt lucky.`,
        `When we got back, we still managed to see the sunset, although it went really fast. We ate some peaches on the wall of the beach and chilled out there.`,
      ]
    }
  ],signature: '20/09/2023 - 21/09/2023'
}

export default post8