import { PostProps } from "@/types"

const post9: PostProps = {
  key: 9,
  x: '36%',
  y: '88%',
  location: 'Crete',
  title: 'Waterfalls, palms and hippies',
  content: [
    {
      type: 'text',
      value: [
        `We had probably the two most exciting days of the vacation ahead of us.`,
        `In the morning, we walked through the balcony onto the beach and observed the sunrise. Then we went back to bed, learned the Greek alphabet on Duolingo, ate breakfast, and made plans for today.`,
        `Soon, we were on the highway, driving south. We approached the first destination, so we stopped at a long parking spot along the road. One side of the road was a giant rock wall and the other side was a steep valley down. It was hiding a beautiful gorge inside it. Kourtaliotiko gorge. It took us several times to memorise this name.`,
        `We walked into the valley through a cute gate and walked down a rocky staircase. We passed by a cute tiny church. Then we saw amazing countless waterfalls inside the valley underneath us. We walked down to approach them and observed them from a closer distance. Then we walked along the gorge to the biggest waterfall and a lake which leads to it.`,
        `There were suddenly a lot of people enjoying this wonder. After a nice photoshoot, we left our things behind and entered an icy cold lake. Even my mom struggled, and she easily swims in an ice hole on the lake in our village in winter. We stood and walked around, our feet were getting numb, and the sun was keeping us warm. When we collected enough strength, we decided to swim in the lake towards the big waterfall.`,
        `This was an absolutely surreal experience and I cannot really use words for it. But I will try. It was like being in a giant dark cave but it had cracks on top so the sun went in and illuminated everything. It exaggerated the water’s blue colour. Now our feet and hands were completely numb, but I totally forgot about my physical body. The waterfall was powerful, loud, big and yet we managed to get really close.`,
        `When walking up the staircase to get back to the car, we were still wet, which helped with handling the heat. We drove to the next location, all the way south this time.`,
        `I am now thinking, how did we manage all of this in one day?`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post9/1.jpg',
          description: ['ice cold water'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post9/2.jpg',
          description: ['on my way towards the waterfall'],
          color: 'blue',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Here, we joined the same gorge, and hiked it down towards the beach. It was hilly - we hiked on the side of the gorge instead of inside it. The landscape was really dry, resembling a rocky desert. The sun was doing its job, I was struggling. My mom was still talkative, but I tried to save every joule of my energy. The trail was only about one hour, maybe one and a half, but we struggled to keep to the path, as it was not really marked properly.`,
        `My enthusiasm was back when I saw our destination - the Preveli beach. The river from the gorge was reaching the sea here. The gorge was pretty shallow now, so the river was almost still and people walked in it towards the gorge. And the most amazing thing - the river created a sort of an oasis and slices of palm tree forests grew alongside it. When we arrived, we immediately got in the river - or more like slided down a muddy slope. I loved having my cooked head underwater.`,
        `We swam and walked towards the gorge. Then we walked in the little palm forest, hung out on the beach, ate a really bad croissant from the one café that was there, had coffee, and I bought a perfect scarf from a talkative hippie woman.`,
        `Actually, this whole place was really hippy. Not at all touristy, it did not seem like big boats go here to bring tourists for a quick stop. You have to work hard to reach this place. A lot of hippie people set up their hammocks and played sports and chilled. I imagined they would spend a few nights in this paradise.`,
        `We left in time, the sun was going to set once we reached the car. The journey was amazing, we both had a sort of a high. The sun was not aggressive anymore, we walked back on the other side of the gorge so we saw everything from a different angle. We saw many amazing weird plants: a flower which looked like the one from the Avatar movie, a tree that looked like the African baobabs. There were some farms with olive trees and goats, and we also saw a church and rang a bell there.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post9/3.jpg',
          description: ['mom at the Preveli beach'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post9/4.jpg',
          description: ['the river with the palm tree forest by the sides'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post9/5.jpg',
          description: ['the view on the gorge'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post9/6.jpg',
          description: ['the view on the river'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post9/7.jpg',
          description: ['the river, Preveli beach and the sea'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post9/8.jpg',
          description: ['a little church we found on our way back home'],
          color: 'pink',
        },
      ]
    },
  ],
  signature: '22/09/2023'
}

export default post9