import { PostProps } from "@/types"

const post3: PostProps = {
  key: 3,
  x: '15%',
  y: '8%',
  zIndex: 120,
  location: 'Ohrid',
  title: 'From 0 to 100',
  content: [
    {
      type: 'text',
      value: [
        `I arrived in Ohrid at around 10. I picked up some food, refused several taxis and walked towards the Sunset Lake Hostel. After checking in and some small talk with my roommates, I fell asleep.`,
        `In the morning, I decided to work in the kitchen. I didn't really know about reliable cafés with plugs and wifi yet. People were coming in and out of the kitchen, I shared conversations with them. It gave me a bit of a FOMO seeing that a lot of people planned hikes on the weekdays. I got slightly worried that I wouldn't have companions for my evening and weekend activities.`,
        `Since I could not really focus on work, I chose to go to a café two other girls mentioned they were going to. One of them works and another one studies. After I ordered my coffee, I saw them wave at me and ask me to join. We didn't talk that much and focused on work, but it was still very nice to have company.`,
        `For this night, I have nothing to write about, since I used this evening to do groceries, withdraw money, organize my space, do laundry and get in touch with loved ones.`,
      ],
    },
    {
			type: 'text',
			value: [
				`The next morning I immediately went to Steve's coffee, this time without companions. I worked till quite late, since my project was soon to be released and it needed some more work.`,
				`When I arrived at the hostel, I was pretty tired. I didn't really see anyone I knew, or people who would be excited to do things together. I took myself on an evening walk. I walked uphill through a forest till I arrived at a fortress, further on at a church. I was gasping, these places look amazing. I enjoyed some nice view and then walked down to the village. The place is rather small, so it is easy to discover it all. I saw pearl shops everywhere - the Ohrid pearl is sort of a symbol. The stores here are opening up around the time when I go to work, and they remain open till about 10. Restaurants play Greek music and people have romantic dinners there. It is a very touristic place, but not in a way that would annoy me. I was really enjoying this romantic atmosphere, although also fully realizing I was alone. It is my second night at the hostel and I am somehow spending it alone.`,
				`When I got back to the hostel, I didn't really find people I would connect with. Before I started worrying, I grabbed a book and went to sit downstairs in the living room. My plan was to enjoy my solitude, instead of trying to fight it.`,
				`Soon enough, I got in a conversation with a Spanish-Mexican couple. I liked third wheeling them, they were fun. Later, more and more people were arriving back at the hostel from their activities and started joining us in the living room. Eventually, we formed quite a large, fun group. We sipped some wine, listened to music and talked. The hostel owner, Gyoko, an amazing friendly guy, was there too and offered everyone some Rakia. My worries about connecting with people were dissolved now.`,
			],
		},
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post3/1.jpg',
          description: ['a staircase behind the hostel which leads to Samoil`s fortress'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post3/2.jpg',
          description: ['a fountain found at one of the churches'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post3/3.jpg',
          description: ['Samoil`s fortress from the distance'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post3/4.jpg',
          description: ['historical streets in Ohrid old town'],
          color: 'pink',
        },
      ]
    },
		{
			type: 'text',
			value: [
				`The next day, my mindset was a lot more relaxed about my possible solitude, but also hopeful that I get to spend some time with the people I met yesterday. I would love to go to the beach with them, but I also imagined that I would enjoy going alone and listen to some music and write my diary.`,
				`Today I ended work earlier, since I had overtime from yesterday. As soon as I crossed the doorstep of the hostel, I got asked by a group of people to join them at the beach. Of course I say yes, I was going there anyway!`,
				`We waited for some more people and then walked. I was meeting some people from yesterday and some new people. We took beautiful pictures at the church, then continued to walk on to the beach through the forest. The place is called Labino beach. It has a cave like coastal rocks. We settled inside one such half-cave and prepared a bonfire. We could see the sunset from here and the mountains on the other side of the lake. Me and 3 other cold resistant people went for a swim. Yes it was very frisky. We swam in the big waves and adored once the mountains, then the coast, then the mountains again.`,
			],
		},
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post3/5.jpg',
          description: ['this cafe had a reliable wifi and I worked here almost every day'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post3/6.jpg',
          description: ['Church of Saint John the Theologian'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post3/7.jpg',
          description: ['the hostel group on a way to the church'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post3/8.jpg',
          description: ['bonfire at the cave by the sea', 'we had a fun night with the hostel friends here'],
          color: 'blue',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Around 9, I made the decision to walk back to the hostel. A Polish guy Daniel and a Polish girl Magdalena decided to join me. When we were walking just above the church, we stopped to pet a really cute dog. The dog owner immediately started talking to us. He is a Macedonian living here, owning some guest rooms. He has a really good understanding of other nationalities and could tell us what he knows about Slovakia and Poland. He very candidly talked about his home, Macedonia, how it used to be some years ago. He talked about the tourism in summer versus the vacant city in winter. The nicest part of our conversation was hearing him talk about Macedonian people. The way they always approach, talk to others, make friends. He was a perfect example! I was yet to discover just how friendly and wholesome they are, but I was already convinced. After a while, the guy left and the three of us remained sitting at the staircase. We were silent for a really long time. We switched our attention between the church, the city lights and the stars. I haven't felt this peaceful in a really long time. It was dark, we heard crickets, the air was still. Time also felt still. Here I was, enjoying this comfortable silence with people I met only a few hours ago, and it just felt perfect. We really took our time before I decided to cut the silence and say I am ready to go home.`,
        `Or that's what I wanted to say but I stumbled upon my words since I forgot how to speak in the meantime.`,
      ]
    },
  ],
  signature: '11/09/2023 - 13/09/2023'
}

export default post3