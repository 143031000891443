import { PostProps } from "@/types"

const post2: PostProps = {
  key: 2,
  x: '20%',
  y: '3%',
  location: 'Skopje',
  title: 'Monumental town',
  content: [
    {
      type: 'text',
      value: [
        `I arrived in Skopje late evening. My building was right next to a giant monumental arch through which you enter the city. My building was, however, not monumental, it was a regular 'socialist' architecture block of flats. I haven't read the instruction on how to check in, and broke in the wrong entrance at first. When I made it to the right entrance, I was a bit scared - on the first impression, the hallways looked so old and sketchy. I met a nice German guy on the staircase, but barely saw his face in the dark staircase. I just talked to his silhouette. When I entered the hostel, it looked pretty much like a regular flat, with the difference that instead of having one bed in the bedroom, there were several bunkbeds. When a warm, beautiful and kind woman welcomed me in the kitchen, all my strange feelings about this place fell off of me. This girl was from the US, looked at me from across the kitchen, offering tea or coffee. She gave me directions for groceries and cafés.`,
        `This evening, I made sure to call my family, rest in the bed and not socialize. It also seemed like people from the hostel went to watch a football match, so I wasn't really missing out. I was pretty relaxed, until the point when I found some bugs in my bed. I made sure to exterminate them all. I texted the owner, but he wasn't reachable anymore, so I decided to get over it and go to sleep.`,
        `I luckily woke up with no bites. The plan for today was to relax as much as possible - I was thinking to just sit in cafés, write, plan more of my journey, and so on... I walked in the kitchen and saw a poster for free walking tour for Skopje. Oh no. I knew at this moment my plans to chill were over. I have to go!`,
        `The German guy from yesterday shared some tea with me and decided to join me for the tour. Him and the tour guide both had good sense of humor, so I was enjoying some good comedy on this tour.`,
        `Skopje is a strange city. On one hand, we have these soviet style blocks of flats, sort of like in the residential areas of Bratislava. On the other hand, there is an insane amount of majestic monuments. To me, they seem a bit forced, pretentious, nationalistic, a bit out of place. They also don't really hold a historical value, since they were all built recently. It was a part of so called Project 2014, which was an aim to build up the city center and attract tourists and locals into the town. 2014 meant that it was supposed to be finished in 2014, but is now more of a joke. The guide said most travel vlogs about Skopje have terrible titles, and I can understand it is tempting to characterize the city with bad words. However, bad PR can work better than good PR! So I am joining in with my ironic title.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post2/1.jpg',
          description: ['one of the monuments', 'but this one I found tasteful'],
          color: 'blue',
        },
        {
          src: '/images/00_balkans/post2/2.jpg',
          description: ['the old bazaar', 'the real beauty could not be captured on my phone'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post2/3.jpg',
          description: ['the trains station with the frozen clock from the earthquake'],
          color: 'pink',
        },
        {
          src: '/images/00_balkans/post2/4.jpg',
          description: ['the cafe in town where I planned the next steps of my journey'],
          color: 'blue',
        },
      ]
    },
    {
      type: 'text',
      value: [
        `A place which did feel authentic was the old bazaar. It was surprisingly big (surprisingly because the Google pictures don't do it justice). Another place which left an impression was the old train station. It used to be quite a large and important station but now there was only a ruin of the right wing left due to an earthquake in the 70s. This earthquake had the strength of 6.1 and was disastrous to the city. Apparently, they decided to keep the ruin, for its large clock on the wall. This clock does not work. It got stuck at the exact time of the earthquake. Now it is there for people to remember this event.`,
        `The guide gave us a good understanding of the atmosphere in the Macedonian society. Macedonians struggle with corruption. Each new prime minister automatically becomes the most hated person in the country. Moreover, about 80% of the population wishes that Macedonia enters the EU. There is a conflict between identity and willingness to comply. The Macedonians already had to change their flag, their name and this is quite hurtful to their national identity. Therefore, you will see people still often using the old flag and they prefer you drop the word 'Northern' and just call it Macedonia.`,
        `After the tour, I said goodbye to the German guy (no idea what his name was just like he probably also forgot mine). I headed to a café and managed to do some planning of my journey. Then I got on a bus to Ohrid and couldn't decide whether I am more tired or excited. I kind of had a feeling that Ohrid will be a great place for me. Just the vibe I got from seeing a few pictures on Google feels right and makes me feel like I will belong there. I realized I am terribly tired but also terribly excited.`,
      ]
    },
  ],
  signature: '10/09/2023'
}

export default post2